import React, { useEffect, useState } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import $ from 'jquery';
// import ourmissionImage from "../../../../public/static/img/our-mission.png"
import { providerListSpecific } from '../../Actions/User.action';

const Dietitian = ({ history }) => {

    const [allProvidersList,setAllProvidersList]= useState([])
    const [allProvidersCount,setAllProvidersCount]= useState(4)
    const [showMenu,setShowMenu]= useState("Show More")
    useEffect(() => {
        document.documentElement.scrollTop = 0;

        /*var a = $('title').text();
        alert($.fn.jquery);*/

    }, []);
    const getAllProviders = async () => {
        const response  = await providerListSpecific("Registered Dietitian");
        setAllProvidersList(await response.data); 
    }
useEffect(()=>{
    getAllProviders();
})
const toggleAllList = () => {
    if(showMenu== "Show More")
    {
        setAllProvidersCount(allProvidersList.length)
        setShowMenu("Show Less")
    }
    else 
    {
        setAllProvidersCount(4)
        window.scrollTo({top:1500,left:0,behavior:"smooth"})
        setShowMenu("Show More")
    }

}
    return (
        <div className='women-health dietitian'>
            {/* <MetaTags>
                <title>Women Health</title>
                <meta name="description" content="To access e-INSCRIPTION’s services through a website or mobile app, you need an account on our platform. To create an account you have to provide accurate info" />
                <meta name="keywords" content="LIVE online video streaming providers Indore, online video streaming providers, Online teaching in Indore, Online Training in Indore, Virtual classes in Indore " />
            </MetaTags> */}
            <section className='women-health-hero'>
                <div className="container">
                    <div className='women-health-hero-inner'>
                        <h1><span>Dietitian</span></h1>
                        <p>Fuel your body, nourish your soul</p>
                    </div>
                    <a class='btn btn-primary' href="#">Learn More</a>
                </div>
            </section >
            <section className='our-mission'>
                <div className="container">
                    <div className="section_title">
                        <h2>Our Mission</h2>
                        <div className="decor2"></div>
                    </div>
                    <div className='our-mission-inner'>
                        <div className="row">
                            <div className="col-md-6">
                                <img src='../static/img/dietitian-img.svg' alt='' />
                            </div>
                            <div className="col-md-6">
                                <h3> Dietitian </h3>
                                <p>
                                    Recover is a telehealth platform that provides clients with quick and easy access to Healthcare Providers wherever and whenever they need it. Healthcare specialists may also use Recover to fill cancelations that may arise within their own practice or to build their practice.
                                    Our goal is to connect clients with world-class providers to regain optimal health.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className='our-service'>
                <div className="container">
                    <div className="section_title">
                        <h2 className=''>Our Services</h2>
                        <div className="decor2"></div>
                    </div>
                    <div className="our-service-container">
                        <div className='our-service-card'>
                            <div className='service-circle'>
                                <img src='../static/img/fax.svg' alt='' />
                            </div>
                            <p>Online appointment scheduling</p>
                        </div>
                        <div className='our-service-card'>
                            <div className='service-circle'>
                                <img src='../static/img/fax.svg' alt='' />
                            </div>
                            <p>Telemedicine services</p>
                        </div>
                        <div className='our-service-card'>
                            <div className='service-circle'>
                                <img src='../static/img/fax.svg' alt='' />
                            </div>
                            <p>Prescription refill requests</p>
                        </div>
                        <div className='our-service-card'>
                            <div className='service-circle'>
                                <img src='../static/img/fax.svg' alt='' />
                            </div>
                            <p>Patient portals</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='health-care-providers'>
                <div className="container">
                    <div className="section_title">
                        <h2>Healthcare Providers</h2>
                        <div className="decor2"></div>
                    </div>
                    <div className="health-care-providers-inner">
                    {allProvidersList?.map((single,index)=>{
                        if(index+1<=allProvidersCount){
                            return (
                                 <div className="health-care-providers-card">
                            <div className="health-care-providers-card-img">
                            <img
                              src={`${process.env.REACT_APP_STATIC_S3_URL + single.profileImg}`}
                              alt="No Profile Pic"
                              onError={(e) => {
                                e.target.src = "/static/img/providerimg.png"
                                e.target.style = ''
                              }}
                            />
                            </div>
                            <div className="health-care-providers-card-content">
                                <h4>Nutritionist</h4>
                                <Link class='btn btn-primary book-app' to="/provider-list">Book Appointmnet</Link>
                            </div>
                        </div>
                        )}
                        })}
                        {/* <div className="health-care-providers-card">
                            <div className="health-care-providers-card-img">
                                <img src='../static/img/dr01.svg' alt='' />
                            </div>
                            <div className="health-care-providers-card-content">
                                <h4>Axel Patterson</h4>
                                <p>Nutritionist</p>
                                <a class='btn btn-primary book-app' href="#">Book Appointmnet</a>
                            </div>
                        </div>
                        <div className="health-care-providers-card">
                            <div className="health-care-providers-card-img">
                                <img src='../static/img/dr02.svg' alt='' />
                            </div>
                            <div className="health-care-providers-card-content">
                                <h4>Anaya Patrick</h4>
                                <p>Registered Dietitian</p>
                                <a class='btn btn-primary book-app' href="#">Book Appointmnet</a>
                            </div>
                        </div>
                        <div className="health-care-providers-card">
                            <div className="health-care-providers-card-img">
                                <img src='../static/img/dr03.svg' alt='' />
                            </div>
                            <div className="health-care-providers-card-content">
                                <h4>Anie Bolt</h4>
                                <p>Clinical Nutritionist</p>
                                <a class='btn btn-primary book-app' href="#">Book Appointmnet</a>
                            </div>
                        </div>
                        <div className="health-care-providers-card">
                            <div className="health-care-providers-card-img">
                                <img src='../static/img/dr04.svg' alt='' />
                            </div>
                            <div className="health-care-providers-card-content">
                                <h4>Bob Halton</h4>
                                <p>Weight Loss Coach</p>
                                <a class='btn btn-primary book-app' href="#">Book Appointmnet</a>
                            </div>
                        </div> */}
                    </div>
                    <div style={{display:"flex", justifyContent:"center", marginTop:"50px"}}>

{allProvidersList.length>4 && <button className='btn btn-primary book-app' onClick={()=>{toggleAllList();}}>{showMenu}</button>}
     </div>
                </div>
            </section>

            <section className='benefits'>
                <div className='container'>
                    <div className="section_title">
                        <h2>Benefits</h2>
                        <div className="decor2"></div>
                    </div>

                    <div className="benefits-row">
                        <div className="benefits-col">
                            <h3>Convenience</h3>
                            <p>With telehealth, you can schedule appointments and consult with a dietitian from the comfort of your own home or office, without having to travel to a medical facility.</p>
                            {/* <ul>
                                <li>
                                    <span className='check-ic'>
                                        <img src='../static/img/check-ic.svg' alt='' />
                                    </span>
                                    Rorem ipsum dolor sit amet, consectetur.
                                </li>
                                <li>
                                    <span className='check-ic'>
                                        <img src='../static/img/check-ic.svg' alt='' />
                                    </span>
                                    Rorem ipsum dolor sit amet, consectetur.
                                </li>
                                <li>
                                    <span className='check-ic'>
                                        <img src='../static/img/check-ic.svg' alt='' />
                                    </span>
                                    Rorem ipsum dolor sit amet, consectetur.
                                </li>
                            </ul> */}
                        </div>
                        <div className="benefits-col">
                            <div className="benefits-col-img">
                                <img src='../static/img/benefits.svg' alt='' />
                            </div>
                        </div>
                    </div>
                    <div className="benefits-row row-inverse">
                        <div className="benefits-col">
                            <h3>Access to specialists</h3>
                            <p>elehealth can help people living in rural or remote areas, where dietitians may not be available nearby, to access specialized care.</p>
                        </div>
                        <div className="benefits-col">
                            <div className="benefits-col-img">
                                <img src='../static/img/benefits02.svg' alt='' />
                            </div>
                        </div>
                    </div>

                    <div className="benefits-row">
                        <div className="benefits-col">
                            <h3>Cost-effective</h3>
                            <p>Telehealth consultations are often more affordable than in-person appointments, as they can reduce travel and other related expenses.</p>
                        </div>
                        <div className="benefits-col">
                            <div className="benefits-col-img">
                                <img src='../static/img/benefits.svg' alt='' />
                            </div>
                        </div>
                    </div>
                    <div className="benefits-row row-inverse">
                        <div className="benefits-col">
                            <h3>Time-saving</h3>
                            <p>With telehealth, you can avoid long wait times at the dietitian's office and can often get an appointment sooner.</p>
                        </div>
                        <div className="benefits-col">
                            <div className="benefits-col-img">
                                <img src='../static/img/benefits02.svg' alt='' />
                            </div>
                        </div>
                    </div>

                    <div className="benefits-row">
                        <div className="benefits-col">
                            <h3>Increased privacy</h3>
                            <p>For sensitive or personal health issues, telehealth consultations can provide a greater sense of privacy and comfort.</p>
                        </div>
                        <div className="benefits-col">
                            <div className="benefits-col-img">
                                <img src='../static/img/benefits.svg' alt='' />
                            </div>
                        </div>
                    </div>
                    <div className="benefits-row row-inverse">
                        <div className="benefits-col">
                            <h3>Continuity of care</h3>
                            <p>Telehealth can provide an opportunity to have more frequent check-ins with your dietitian, ensuring continuity of care and better management of your health.</p>
                        </div>
                        <div className="benefits-col">
                            <div className="benefits-col-img">
                                <img src='../static/img/benefits02.svg' alt='' />
                            </div>
                        </div>
                    </div>

                    <div className="benefits-row">
                        <div className="benefits-col">
                            <h3>Greater flexibility</h3>
                            <p>Telehealth consultations can offer more flexibility in terms of scheduling appointments outside of traditional office hours or when a dietitian is not available in-person.</p>
                        </div>
                        <div className="benefits-col">
                            <div className="benefits-col-img">
                                <img src='../static/img/benefits.svg' alt='' />
                            </div>
                        </div>
                    </div>
                    <div className="benefits-row row-inverse">
                        <div className="benefits-col">
                            <h3>Increased comfort</h3>
                            <p>Some people may feel more comfortable and at ease discussing sensitive topics like weight or eating habits in the privacy of their own home, making it easier to engage with a registered dietitian via telehealth.</p>
                        </div>
                        <div className="benefits-col">
                            <div className="benefits-col-img">
                                <img src='../static/img/benefits02.svg' alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='consulting-specialist'>
                <div className="container">
                    <div className="section_title">
                        <h2>Consulting Specialist</h2>
                        <div className="decor2"></div>
                    </div>
                    <div className="consulting-specialist-inner">
                        <div className="consulting-specialist-col">
                            <div className="consulting-specialist-ic">
                                <img src='../static/img/dietitian_ic_01.svg' alt='' />
                            </div>
                            <div className="consulting-content">
                                {/* <h3>Covid - 19 Test</h3> */}
                                <p>Assess and evaluate the nutritional needs of clients</p>
                            </div>
                        </div>
                        <div className="consulting-specialist-col">
                            <div className="consulting-specialist-ic">
                                <img src='../static/img/dietitian_ic_02.svg' alt='' />
                            </div>
                            <div className="consulting-content">
                                {/* <h3>Covid - 19 Test</h3> */}
                                <p>Develop personalized meal plans based on client’s health goals, medical conditions, and lifestyle.</p>
                            </div>
                        </div>
                        <div className="consulting-specialist-col">
                            <div className="consulting-specialist-ic">
                                <img src='../static/img/dietitian_ic_03.svg' alt='' />
                            </div>
                            <div className="consulting-content">
                                {/* <h3>Covid - 19 Test</h3> */}
                                <p>Provide education and counseling to help clients make healthier food choices and achieve their nutrition-related goals.</p>
                            </div>
                        </div>
                        <div className="consulting-specialist-col">
                            <div className="consulting-specialist-ic">
                                <img src='../static/img/dietitian_ic_04.svg' alt='' />
                            </div>
                            <div className="consulting-content">
                                {/* <h3>Covid - 19 Test</h3> */}
                                <p>Advise clients with chronic diseases such as diabetes, heart disease, and kidney disease on how to manage their condition through diet and lifestyle changes.</p>
                            </div>
                        </div>
                        <div className="consulting-specialist-col">
                            <div className="consulting-specialist-ic">
                                <img src='../static/img/dietitian_ic_05.svg' alt='' />
                            </div>
                            <div className="consulting-content">
                                {/* <h3>Covid - 19 Test</h3> */}
                                <p>Educate clients about the role of nutrients in the body, the effects of food on health, and how to read food labels.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='faq-wrapper'>
                <div className="container">
                    <div className="section_title">
                        <h2>Frequently Asked Questions</h2>
                        <div className="decor2"></div>
                    </div>
                    <div className="faq-inner">
                        <div className="faq-col">
                            <div className="faq-img-wrapper">
                                <img src='../static/img/faq_dietitian.svg' alt='' />
                            </div>
                        </div>
                        <div className="faq-col faq-content">
                            <div id="accordion">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                How can I schedule a telehealth appointment with a nutritionist?
                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Search for a nutritionist on the Recover platform and request an appointment for the day and time you need. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                What technology do I need for a telehealth appointment?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div className="card-body">
                                            You may use a laptop, PC, tablet, or phone with Google Chrome.  The device must have sufficient video and sound capabilities.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Will my insurance cover telehealth appointments with a nutritionist?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                        <div className="card-body">
                                            Recover does bill insurance.  Clients may submit claims to their own insurance company for reimbursement.  The cash rate Recover charges per nutritionist will be listed during client booking.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingFour">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                                How do I access my medical records or test results through telehealth?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                        <div className="card-body">
                                            You will be able to view past notes in Appointment/Payment History. You may use your default print feature (Ctl +P) to print. You may also email <a href='mailto:info@recover.healthcare'>info@recover.healthcare</a> to obtain a copy of your note.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    );
}
export default withLocalize(Dietitian);