import React,{useState, useEffect} from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { FormGroup, Label, Input} from 'reactstrap';
import {FormError,ShowFormErrors} from "../../../../_Common/formError";
import toastr from "../../../../_Common/toaster";
import {getUserProfileAction, editProfileAction, getPayCartListAction, setDefaultCardAction, deletePaymentCartAction} from '../../../Actions/User.action';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import {appConstants,setSessionValue,toUTC} from '../../../../_Constants/app.constants';
import {timezones} from '../../../../_Constants/timezones';
import GeoAddressComponent from '../../../../_Common/GeoAddressManage';
import AddMoreFileUpload from '../../../../_Common/addMoreFileUpload';
import moment from 'moment';
import $ from 'jquery';
import { Dialog } from "@blueprintjs/core";
import ChangePassword from '../ChangePassword/index';
import NumberFormat from 'react-number-format';
import SideMenu from '../SideMenu';
import PatientMedicalHistory from './PatientMedicalHistory';
import PatientDocuments from './PatientDocuments';

const ProfilePage = ({history, setIsSignInForm, setIslogedIn}) => {
  const [signupForm,setSignupForm] = useState({});
  const [signupErrors,setSignupErrors] = useState({});
  const [isChangePassword,setIsChangePassword] = useState(false);
  const [dobValue, setDobValue] = useState();
  const [isGender, setIsGender] = useState(false);
  const { register, handleSubmit, setValue, errors, setError } = useForm();
  const [EditStatus, setEditStatus] = useState(false);
  const [payCartList,setPayCartList] = useState(false);
  const [radioDefault,setRadioDefault] = useState(false);
  const [loadingBtn,setLoadingBtn] = useState(false);
  const [dobDisableStatus, setDobDisableStatus] = useState(false);
  const [spinner,setSpinner] = useState(false);

  useEffect(()=>{
    getUserProfileDetil();
    getPayCartListFunction();
    $('input, select').prop('disabled', true);
    $('.medicalHistory_wrapinner input, select').prop('disabled', true);
    setDobDisableStatus(true);
  },[]);

  const getUserProfileDetil = async(status)=>{
    let userDetail = await getUserProfileAction();
    if(status==='UPDATE_SESSION' && userDetail && userDetail.data){
      setSessionValue(userDetail.data);
      window.location.reload();
    }
    userDetail.data.time_zone = userDetail.data.timeZone.text;
    userDetail.data.timeZone = userDetail.data.timeZone && userDetail.data.timeZone?JSON.stringify(userDetail.data.timeZone):'';
    setSignupForm(userDetail.data);
    if(userDetail && userDetail.data!==''){
      
      let dobDateValue =userDetail.data.dob?new Date(userDetail.data.dob):'-';
      
      setDobValue(dobDateValue);
    }
  }  

  const getPayCartListFunction = async()=>{
    let cartList = await getPayCartListAction();
    if(cartList.success===true){
      cartList.data.map((val, ind)=>{
        if(val.is_default==='true')
        setRadioDefault(val._id)
      })
      setPayCartList(cartList.data);
    }
  }

  const setDefaultCard = async(e,id)=>{
    e.preventDefault();
    if(id && id!==''){
      confirmAlert({
        title: 'Set a primary card',
        message: 'Are you sure you want to set this card as a primary card for future payments?',
        buttons: [{
            label: 'Yes',
            onClick: async() => {               
                setRadioDefault(id)
                let resCard = await setDefaultCardAction({cardId:id});
                if(resCard.success===true){
                  toastr.show({message: resCard.msg,intent: "success"});
                }else if(resCard.success===false){
                  getPayCartListFunction();
                  toastr.show({message: resCard.msg,intent: "danger"});
                }
            }
          },
          {label: 'No',}
        ]
      });
    }
  }  

  const deleteCard = async(e,id)=>{
    e.preventDefault();
    if(id && id!==''){
      confirmAlert({
        title: 'Confirm to Delete',
        message: 'Are you sure to do this?',
        buttons: [{
            label: 'Yes',
            onClick: async() => {               
                let resCard = await deletePaymentCartAction({cardId:id});
                if(resCard.success===true){
                  getPayCartListFunction();
                  toastr.show({message: resCard.msg,intent: "success"});
                }else if(resCard.success===false){
                  toastr.show({message: resCard.msg,intent: "danger"});
                }
            }
          },
          {label: 'No',}
        ]
      });
    }
  }

  const inputHandler = (e,field)=>{
    let signupFormData = signupForm;
    if(field === 'phone' && e.target.value.replace(/[-]/g, '').trim().length === 10){
      $('input[name="phone"]').removeClass("requiredClass");
    }
    if(field == 'time_zone'){
        let timezoneValue = timezones.filter(
            function(timezones){return timezones.text == e.target.value}
        );      
        signupFormData['timeZone'] = JSON.stringify(timezoneValue[0]);
    }
    signupFormData[field] = e.target.value;
    setSignupForm({...signupForm,...signupFormData});
  }

  const radioHandler = (e,field)=>{
    let signupFormData = signupForm;
    signupFormData[e.target.name] = field;
    setSignupForm({...signupForm,...signupFormData});
  }

  const fileChangedHandler=(event, elename)=>{
    event.preventDefault();
    if(!event.target.files[0] && event.target.files[0]===undefined){
      return false;
    }
    let signupFormData = signupForm;
    let file = event.target.files[0];
    var fileName = (file && file.name?file.name:'');
    let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
    if (extensions === 'bmp' || extensions === 'jpg' || extensions === 'png' || extensions === 'jpeg') {
        signupFormData[elename+'Preview'] = URL.createObjectURL(event.target.files[0]);
        signupFormData[elename] = event.target.files[0];
        setSignupForm({...signupForm,...signupFormData});
    } else {
        let err = [{"msg": "Extensions doesn't match","param": elename}];
        setSignupErrors(FormError(err));
    }
  }

  const onFormSubmit = async () =>{
    setSpinner(true);
    /*if(signupForm && signupForm.uploadDocs===undefined && signupForm.paDocs.length === 0){
      $('div #titleMsg').html('Required');
      $('div #imgPathMsg').html('Required');
      setSpinner(false);
      return false;
    }*/
    if(signupForm && signupForm.uploadDocs!==''){
      var uploadDocs = signupForm.uploadDocs;
      var titleArray = [];
      var imageArray = [];
      for(let x in uploadDocs){
        if(!uploadDocs[x]._id){
          if(uploadDocs[x].title!=='' && uploadDocs[x].imgPath!=='' && uploadDocs[x].imgPath!==undefined){
            titleArray.push({'title': uploadDocs[x].title, 'size': uploadDocs[x].size});
            imageArray.push(uploadDocs[x].imgPath);
          }/*else{
            $('div #titleMsg').html('Required');
            $('div #imgPathMsg').html('Required');
            setSpinner(false);
            return false;
          }*/
        }
      }
      signupForm['title'] = JSON.stringify(titleArray);
      signupForm['image'] = imageArray;
    }
    signupForm['phone'] = signupForm['phone'].replace(/\D/g,'');
    signupForm['dob']=dobValue?toUTC(dobValue):'';
    //signupForm['dob'] = dobValue?moment(dobValue, "YYYY-MM-DD").format("YYYY-MM-DD"):'';
    signupForm['timeZone'] = signupForm['timeZone'];
    let res = {};
    let err = {};
    let formErrors = {};
    res = await editProfileAction(signupForm);
    if(res.err && res.err.length > 0){
      formErrors = FormError(res.err);
    }
    setSignupErrors(formErrors);
    if(res.success){
      getUserProfileDetil('UPDATE_SESSION');
      setEditStatus(false);
      setSpinner(false);     
      $('input, select').prop('disabled', true);
      toastr.show({message: res.msg,intent: "success"});
    }else if(res.success===false){
      setSpinner(false);
      toastr.show({message: res.msg,intent: "danger"});
    }
  }

  const onEditClick = (e, status)=>{
    e.preventDefault();
    getUserProfileDetil();
    setEditStatus(!status);
    setDobDisableStatus(false);
    $('input, select').prop('disabled', status);
    $('input[name=address]').prop('disabled', true);
    $('input[name=city]').prop('disabled', true);
    $('input[name=state]').prop('disabled', true);
    $('input[name=zipCode]').prop('disabled', true);
    $('input[name=email]').prop('disabled', true);
    $('.credit_card_box_row input[type=text]').prop('disabled', true);
    $('.medicalHistory_wrapinner input, select').prop('disabled', true);
    setTimeout(function(){
      $('select[name=time_zone]').prop('disabled', false);
    },1000)
  }

  const formHandleraCall = (field,name)=>{
    let formData1 = signupForm;
    formData1[name] = field;
    setSignupForm(formData1);
  }  

  return (
    <div className="row">
      <div className="col-md-3 left_dashboard scrollbar2 leftContainer">
        <SideMenu/>
      </div>
      <div className="col-md-9 right_dashboard scrollbar3 rightContainer">
        <div className="content-setion">
          <div className="section_title m-25">
            <h3>My Profile</h3>
          </div>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="row register_patient_row2">
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-6">
                    <label>First Name*</label>
                     <div className="form-group">
                      <input onChange={e=>inputHandler(e,'firstName')} name="firstName" type="text" 
                        className="form-control" placeholder="First Name" 
                        defaultValue={signupForm.firstName?signupForm.firstName:''}
                        ref={register({
                          required: 'Required'
                        })}
                      />
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"firstName")}</div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Last Name*</label>
                    <div className="form-group">
                      <input onChange={e=>inputHandler(e,'lastName')} name="lastName" type="text" 
                        className="form-control" placeholder="Last Name" 
                        defaultValue={signupForm.lastName?signupForm.lastName:''}
                        ref={register({
                          required: 'Required'
                        })}
                      />
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"lastName")}</div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Date of Birth*</label>
                    <div className="form-group">
                      <DatePicker
                        name='dob' 
                        selected={dobValue}
                        maxDate={new Date()}
                        className="form-control"
                        dateFormat={appConstants.DateFormatNew}  
                        placeholderText="Click to select a Date of Birth"
                        onChange={date => { setDobValue(date); setValue("dob", date);}}
                        ref={register({name: "dob"}, {required: dobValue?'':'Required'} )}
                        showMonthDropdown
                        useShortMonthInDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                        disabled={dobDisableStatus}
                      />
                      {!dobValue &&
                        (<div className="text-danger">{ShowFormErrors(errors,signupErrors,"dob")}</div>)
                      }
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Gender*</label>
                    <div className="radio-bb">
                      <FormGroup check>
                        <Label check htmlFor='forMale'>
                        <input type="radio" className="clas-radio" id="forMale" 
                        name="gender" onChange={e=>radioHandler(e,'male')}
                        checked={signupForm.gender === 'male'?'checked':''}
                        /> Male </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check htmlFor='forFemale'><Input type="radio" className="clas-radio" id="forFemale" 
                        name="gender" onChange={e=>radioHandler(e,'female')}
                        checked={signupForm.gender === 'female'?'checked':''} 
                        /> Female </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check htmlFor='forOther'><Input type="radio" className="clas-radio" id="forOther" 
                        name="gender" onChange={e=>radioHandler(e,'other')}
                        checked={signupForm.gender === 'other'?'checked':''} 
                        /> Other </Label>
                      </FormGroup>
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"gender")}</div>
                    </div>
                  </div>    
                  <div className="col-lg-6">
                    <label>Email*</label>
                    <div className="form-group">
                      <input onChange={e=>inputHandler(e,'email')} name="email" type="email" 
                        className="form-control" placeholder="Email" 
                        defaultValue={signupForm.email?signupForm.email:''}
                        ref={register({
                          required: 'Required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Please enter a valid email address."
                          }
                        })}
                      />
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"email")}</div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Phone Number*</label>
                    <div className="form-group phone_field">
                      <select className="form-control" name="phoneCode" >
                        <option defaultValue="+1">+1</option>
                      </select>
                      <NumberFormat
                        format="###-###-####"
                        name="phone"
                        //pattern="[0-9,-]{12}"
                        placeholder="Phone Number Here"
                        className="form-control"
                        value={signupForm.phone?signupForm.phone:''}
                        onChange={e =>{ inputHandler(e, 'phone'); setValue("phone", e.target.value);}}
                        ref={register(signupForm.phone===''?{name: "phone"}:'', 
                          {required: signupForm.phone===''?'Required':'',
                          pattern: {value: /^(?=.*\d)[\d-]{12}$/, message: "Phone number should be 10 digit number."}
                        } )}
                      />
                      {signupForm.phone==='' &&
                        <div className="text-danger">{ShowFormErrors(errors,signupErrors,"phone")}</div>
                      }
                      {signupForm.phone && signupForm.phone.replace(/[-]/g, '').trim().length!==10 && 
                        <div className="text-danger">{ShowFormErrors(errors,signupErrors,"phone")}</div>
                      }
                    </div>
                  </div>

                  <GeoAddressComponent
                   	  history={history}
                      register={register}
                      errors={errors}
                      formData={signupForm}
                      inputHandler={inputHandler}
                      signupErrors={signupErrors}
                  />                 

                  <div className="col-lg-12 docheading"> 
                    <div className="document_hover"><h4>Important Document</h4>
                      <div className="document_hoverinner">
                        <i className="fa fa-info" aria-hidden="true"></i>
                        <ul><li> Maximum 5 docs can be uploaded.</li>
                        <li> Docs should be in PDF, Word, Excel, PNG, JPG and JPEG Format.</li>
                        <li> Maximum of 5 MB docs can be uploaded.</li></ul> 
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {EditStatus &&
                      <AddMoreFileUpload
                        className="btn"
                        buttonLabel="Click"
                        name="uploadDocs"
                        parentCallback={e=>formHandleraCall(e,"uploadDocs")}
                        deleteOption={true} 
                        formData={signupForm.paDocs}
                      />
                    }
                  </div>
                  <div className="col-lg-12">
                    <div className="row document_sec">
                      {!EditStatus && signupForm.paDocs && 
                        <PatientDocuments history={history} paDocs={signupForm.paDocs?signupForm.paDocs:''} />
                      }
                    </div>
                  </div>
                  <div className="col-lg-12 t-pp">
                    <Link to="#" className="btn btn-primary rt" onClick={e=>onEditClick(e, EditStatus)}>{EditStatus?'Cancel':'Edit'}</Link>
                    <input type="submit" className="btn btn-primary" id={loadingBtn?'loading_btn':''} value="Update" />
                  </div>
                </div> 
              </div>
              <div className="col-lg-3 text-center">
                <div className="bg-cirpatient">
                  <div className="prof_section">
                    <label >
                      {signupForm.profileImgPreview && signupForm.profileImgPreview !=='' &&
                        <img src={signupForm.profileImgPreview} alt="img"/>
                      }
                      {signupForm.profileImg && signupForm.profileImg !=='' && !signupForm.profileImgPreview &&
                        <img src={`${process.env.REACT_APP_STATIC_S3_URL+signupForm.profileImg}`} alt="img"/>
                      }
                      { !signupForm.profileImg && !signupForm.profileImgPreview &&
                        <img src="/static/img/icon-profiles.png" alt="img"/>
                      }
                      <input type="file" name="profileImg" accept=".bmp,.jpg,.png,.jpeg" 
                        onChange={(e)=>fileChangedHandler(e, "profileImg")} style={{ display: 'none' }}
                      />
                    </label>
                    <br/><br/><div className="text-danger">{ShowFormErrors(errors,signupErrors,"profileImg")}</div>
                  </div>
                  <div className="userid">
                    <button type="button" className="nbtn" onClick={e=>setIsChangePassword(true)}><span>Change Password</span></button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="accordion md-accordion patientaccordian" id="accordionEx" role="tablist" aria-multiselectable="true">
            <div className="card">
              <div className="card-header" role="tab" id="headingOne1">
                 <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                    <h5 className="mb-0">
                       Medical History<i className="fas fa-angle-down rotate-icon"></i>
                    </h5>
                 </a>
              </div>
              <div id="collapseOne1" className="collapse medicalHistory_wrap" role="tabpanel" aria-labelledby="headingOne1" data-parent="#accordionEx">

                  <PatientMedicalHistory history={history} signupForm={signupForm?signupForm:''} />

              </div>
           </div>
           <div className="card">
              <div className="card-header" role="tab" id="headingTwo2">
                 <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                    <h5 className="mb-0">
                       Card Information  <i className="fas fa-angle-down rotate-icon"></i>
                    </h5>
                 </a>
              </div>
              <div id="collapseTwo2" className="collapse" role="tabpanel" aria-labelledby="headingTwo2" data-parent="#accordionEx">
                 <div className="card-body credit_card_box">

                  {payCartList && payCartList.length > 0 ? (
                    payCartList.map((val, ind)=>{
                      return (<div className="row credit_card_box_row" key={ind}>
                        <div className="col-lg-3">
                          <label>Card Holder Name</label>
                          <div className="form-group" >
                            <input type="Text" className="form-control" disabled={true}
                              defaultValue={val.name?val.name:''}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <label>Card Number</label>
                          <div className="form-group" >
                            <input type="Text" className="form-control" disabled={true}
                              defaultValue={val.braintreeMaskedNumber?'XXXX-XXXX-XXXX-'+val.braintreeMaskedNumber:''}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <label>Card Type</label>
                          <div className="form-group" >
                            <input type="Text" className="form-control" disabled={true}
                              defaultValue={val.responseData && val.responseData[0].brand ? val.responseData[0].brand:''}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group set_primary1" >
                            <div className="radio-bb">
                              <div className="form-check">
                                <label htmlFor="PrimaryCreditCard" className="form-check-label">
                                <input type="radio" className="clas-radio" name="PrimaryCreditCard"
                                  checked={radioDefault===val._id?true:false}
                                  onChange={e=>setDefaultCard(e,val._id)}
                                />Primary Card</label>
                              </div>                              
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div className="form-check">
                                <button type="button" className="btn btn-danger" onClick={e=>deleteCard(e,val._id)}><i className="fa fa-trash" aria-hidden="true"></i></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>)
                    })
                  ) : (
                    <div className="row credit_card_box_row" >
                      <div className="col-lg-12">
                        <center><label>No card details saved</label></center>
                      </div>
                    </div>
                  )}
              
                 </div>
              </div>
           </div>

        </div>
        </div>


          <Dialog className="form_designpage" onClose={e=>setIsChangePassword(false)}
            title="" canOutsideClickClose={false} isOpen={isChangePassword}>
              <ChangePassword history={history} setIsChangePassword={setIsChangePassword} />
          </Dialog>
          <div className="spinner" style={{display:spinner?'block':'none'}}>
            <img src="/static/img/spinner.gif" alt='loader'/>
          </div>
        </div>
      </div>
    
  );
}
export default withRouter(ProfilePage);
