import React,{useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import $ from 'jquery';

const AboutUsPage = ({history}) => {

  useEffect(()=>{
    document.documentElement.scrollTop = 0;

    /*var a = $('title').text();
    alert($.fn.jquery);*/

  },[]);

  return (
    <div>
    <MetaTags>
      <title>About Us</title>
      <meta name="description" content="To access e-INSCRIPTION’s services through a website or mobile app, you need an account on our platform. To create an account you have to provide accurate info" />
      <meta name="keywords" content="LIVE online video streaming providers Indore, online video streaming providers, Online teaching in Indore, Online Training in Indore, Virtual classes in Indore " />
    </MetaTags>
    <section className="about_page pad-reggis inner_pagewrap">
      <BreadcrumbsComp bcChanges={[{path: '/about-us', breadcrumb: 'About-us'}]} />
      
      <div className="container">
        <div className="row white_box">
          <div className="col-lg-12 col-sm-12">
            <div className="section_title page_ttl text-center">
              <h3>About Us</h3>
              <div className="decor2"></div>
            </div>
          </div>
          <div className="col-lg-12 col-sm-12 text-left">
            <div className="about_con_wrap">
              <p>Recover is a telehealth platform that allows clients to easily access a variety of healthcare specialist services from a single location.  By offering virtual consultations, we aim to connect patients and providers with greater ease and convenience.</p>
               <p>Our platform also allows patients to receive comprehensive treatment plans, reducing missed appointments due to inconvenience.  Additionally, providers can use Recover to fill cancelations within their practice.  Our goal is to make healthcare more accessible and efficient for all.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
}
export default withLocalize(AboutUsPage);
