import React,{useState,useEffect} from 'react';
import { withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import toastr from "../../../_Common/toaster";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {appConstants} from '../../../_Constants/app.constants';
import {FormError,ShowFormErrors} from "../../../_Common/formError";
import { companyAccountUpdateAction, updateAccountPersonAction, getAccountListAction, merchantCategoryCodeListAction, updateAccountAction, payoutFileUploadsAction, deleteAccountAction } from '../../Actions/User.action';
import $ from 'jquery';
import moment from "moment";
import NumberFormat from 'react-number-format';
import { confirmAlert } from 'react-confirm-alert'; 
import GeoAddressComponent from '../../../_Common/GeoAddressManage';


const CompanyAccount = (props) => {
  //console.log(props);
  console.log(localStorage);
  const [formData,setFormData] = useState({});
  const [formDataErrors,setFormDataErrors] = useState({});
  const {register, handleSubmit, setValue, errors, setError, reset } = useForm();
  const [accountStatus,setAccountStatus] = useState(false);
  const [accountDetail,setAccountDetail] = useState(props.accountDetail);
  const [userDetailStatus,setUserDetailStatus] = useState(false);
  const [mccList,setMccList] = useState(props.mccList);
  const [mccValue,setMccValue] = useState("8099");
  const [dobValue, setDobValue] = useState();
  const [spinner,setSpinner] = useState(false);
  const [valueReset,setValueReset] = useState(true);
  const [companyStatus,setCompanyStatus] = useState(false);
  const [personStatus,setPersonStatus] = useState(false);

  useEffect(()=>{
    
    tempPhoneNumber();
    document.documentElement.scrollTop = 0;
  },[]);

  const tempPhoneNumber = ()=>{  

      let dateDOB = moment().subtract(13, 'year')._d;
    
      formData['phone_number'] = localStorage.getItem("phone")?localStorage.getItem("phone"):'';
      formData['email'] = localStorage.getItem("email")?localStorage.getItem("email"):'';

      formData['address'] = localStorage.getItem("address")?localStorage.getItem("address"):'';
      formData['aptStreet'] = localStorage.getItem("aptStreet")?localStorage.getItem("aptStreet"):'';
      formData['city'] = localStorage.getItem("city")?localStorage.getItem("city"):'';
      formData['state'] = localStorage.getItem("state")?localStorage.getItem("state"):'';
      formData['zipCode'] = localStorage.getItem("zipCode")?localStorage.getItem("zipCode"):'';

      setMccValue("8099");//For default industry->Medical service    
      
      if(localStorage.getItem("dob") && localStorage.getItem("dob")!==undefined){

          if(new Date(dateDOB) >= new Date(localStorage.getItem("dob"))){
              setDobValue(new Date(localStorage.getItem("dob")));
          }
      }
      setFormData({...formData});

      //setFormData({'phone_number':''});
      
  }
  
  const getAccountListFunction = async(userInfo)=>{
      
      //setAccountStatus(true);
      setAccountDetail(userInfo);
      if(userInfo && userInfo!==''){
        setUserDetailStatus(true);
        setFormData(userInfo)
      }else{
      
        //formData['phone_number'] = localStorage.getItem("phone")?localStorage.getItem("phone"):'';
        //formData['email'] = localStorage.getItem("email")?localStorage.getItem("email"):'';
        setMccValue("8099");//For default industry->Medical service       
        setFormData({...formData});
      }
    
  }

  const inputHandler = (e)=>{
    if(e.target.name === 'industry' ){
      setMccValue(e.target.value);
    }else{
      let formDataNew = formData;
      if(e.target.name === 'phone_number' && e.target.value.replace(/[-]/g, '').trim().length === 10){
        $('input[name="phone_number"]').removeClass("requiredClass");
      }
      formDataNew[e.target.name] = e.target.value;
      setFormData({...formData,...formDataNew});
    }
  }

  const fileChangedHandler=(event, elename)=>{
    event.preventDefault();
    let formDataNew = formData;
    let file = event.target.files[0];
    if(!file && file===undefined){
      formDataNew[elename+'Preview'] = '';
      formDataNew[elename] = '';
      setFormData({...formData,...formDataNew});
      return false;
    }
    var fileName = (file && file.name?file.name:'');
    let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
    if (extensions == 'bmp' || extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg'){
        formDataNew[elename+'Preview'] = URL.createObjectURL(event.target.files[0]);
        formDataNew[elename] = event.target.files[0];
        setFormData({...formData,...formDataNew});
    }else if(extensions == 'docx'|| extensions == 'doc'){
        formDataNew[elename+'Preview'] = '/static/img/docIcon.png';
        formDataNew[elename] = event.target.files[0];
        setFormData({...formData,...formDataNew});
    }else if(extensions == 'pdf'){
        formDataNew[elename+'Preview'] = '/static/img/pdfIcon.png';
        formDataNew[elename] = event.target.files[0];
        setFormData({...formData,...formDataNew});
    }else {
      let err = [{"msg": "Extensions doesn't match","param": elename}];
      setFormDataErrors(FormError(err));
    }
  }


  const onAccountUpdate = async () =>{
    
    setSpinner(true);
    formData['phoneCode'] = "+1";    
    formData['phone_number'] = formData['phone_number']?formData['phone_number'].replace(/\D/g,''):'';
    formData['acct_id'] = props.accountDetail.accId?props.accountDetail.accId:'';
    formData['address1'] = formData && formData.address?formData.address:'';
    formData['postal_code'] = formData && formData.zipCode?formData.zipCode:'';
    formData['industry'] = mccValue && mccValue?mccValue:'';
    
    let frontImg = '';
    let backImg = '';
    if(formData.photoFront && formData.photoFront!==''){
      let resFront = await payoutFileUploadsAction(formData.photoFront);
      if(resFront.success){
        frontImg = resFront.data.id?resFront.data.id:'';
      }
    }
    if(formData.photoBack && formData.photoBack!==''){
      let resBack = await payoutFileUploadsAction(formData.photoBack);
      if(resBack.success){
        backImg = resBack.data.id?resBack.data.id:'';
      }
    }
    if(frontImg && frontImg!==''){
      formData['front'] = frontImg;
      formData['back'] = backImg;
      
      let res = await companyAccountUpdateAction(formData);
      if(res.success){        
        setSpinner(false);
        //getAccountListFunction();
        toastr.show({message: "Company Added Successfully",intent: "success"});
        setCompanyStatus(true);
        //setFormData({});  
        formData['first_name'] = localStorage.getItem("firstName")?localStorage.getItem("firstName"):'';
        formData['last_name'] = localStorage.getItem("lastName")?localStorage.getItem("lastName"):'';
        formData['phone_number2'] = localStorage.getItem("phone")?localStorage.getItem("phone"):'';

        formData['email'] = localStorage.getItem("email")?localStorage.getItem("email"):'';
  
        formData['address'] = localStorage.getItem("address")?localStorage.getItem("address"):'';
        formData['aptStreet'] = localStorage.getItem("aptStreet")?localStorage.getItem("aptStreet"):'';
        formData['city'] = localStorage.getItem("city")?localStorage.getItem("city"):'';
        formData['state'] = localStorage.getItem("state")?localStorage.getItem("state"):'';
        formData['zipCode'] = localStorage.getItem("zipCode")?localStorage.getItem("zipCode"):'';
                     
        setFormData({...formData});
        //setFormData({'phone_number2':''});        
        
      }else if(res.success===false){
        setSpinner(false);
        toastr.show({message: res.msg,intent: "danger"});
      }
    }
  }


  const onPersonAccountUpdate = async () =>{
    setSpinner(true);
    formData['phoneCode'] = "+1";
    formData['dob_day'] = dobValue?new Date(dobValue).getDate():'';
    formData['dob_month'] = dobValue?new Date(dobValue).getMonth() + 1:'';
    formData['dob_year'] = dobValue?new Date(dobValue).getFullYear():'';
    formData['phone_number'] = formData['phone_number2']?formData['phone_number2'].replace(/\D/g,''):'';
    formData['acct_id'] = props.accountDetail.accId?props.accountDetail.accId:'';
    formData['person_id'] = props.accountDetail.person_id?props.accountDetail.person_id:'';
    formData['address1'] = formData && formData.address?formData.address:'';
    formData['postal_code'] = formData && formData.zipCode?formData.zipCode:'';
    formData['industry'] = mccValue && mccValue?mccValue:'';
    formData['id_number'] = formData && formData.personal_id_number?formData.personal_id_number:'';
    
    let frontImg = '';
    let backImg = '';
    if(formData.photoFront && formData.photoFront!==''){
      let resFront = await payoutFileUploadsAction(formData.photoFront);
      if(resFront.success){
        frontImg = resFront.data.id?resFront.data.id:'';
      }
    }
    if(formData.photoBack && formData.photoBack!==''){
      let resBack = await payoutFileUploadsAction(formData.photoBack);
      if(resBack.success){
        backImg = resBack.data.id?resBack.data.id:'';
      }
    }
    if(frontImg && frontImg!==''){
      formData['front'] = frontImg;
      formData['back'] = backImg;
      let res = await updateAccountPersonAction(formData);
      if(res.success){
        setSpinner(false);
        setPersonStatus(true);
        //getAccountListFunction();
        toastr.show({message: "Account Added Successfully",intent: "success"});
        window.location.reload(); 
        
      }else if(res.success===false){
        setSpinner(false);
        toastr.show({message: res.msg,intent: "danger"});
      }
    }
  }

  const deletePayoutAccount =(e)=>{
    if(accountDetail && accountDetail._id!=='' && accountDetail._id!==undefined){
      confirmAlert({
        title: 'Confirm to Remove',
        message: 'Are you sure you want to delete the existing banking account?',
        buttons: [{
            label: 'Yes',
            onClick: async() => {       
              let res = await deleteAccountAction({_id :accountDetail._id});  
              if(res.success){    
                  setAccountStatus(false);
                  setFormData({});
                  toastr.show({message: res.msg,intent: "success"}); 
                  window.location.reload(); 
              }
            }
          },
          {label: 'No',}
        ]
      });
    }
  }

  const setResetForm = (e)=>{
    window.location.reload();
  }

  return (<div className="content-setion">
                
            <div className="add_account_form">
              { companyStatus == false && 
              <form onSubmit={handleSubmit(onAccountUpdate)}>
                <div className="row">                  
                  <div className="col-lg-12">
                    <label>Legal Business Name*</label>
                    <div className="form-group">
                      <input type="text" name="name" className="form-control" placeholder="Legal Business Name" onChange={e=>inputHandler(e)} defaultValue={formData && formData.name?formData.name:''} ref={register({ required: 'Required' })} />
                      <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"name")}</div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <label>TIN*</label>
                    <div className="form-group">
                      <input type="number" name="tax_id" className="form-control" placeholder="TIN" onChange={e=>inputHandler(e)} ref={register({ required: 'Required', pattern: { value: /^[0-9]{9}$/i, message: "Please enter a valid TIN." }})}  defaultValue={formData.tax_id?formData.tax_id:''} />
                      <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"tax_id")}</div>
                    </div>
                  </div>
                 
                  <div className="col-lg-12">
                    <label>Doing Business As</label>
                    <div className="form-group">
                      <input type="text" name="doingBusiness" onChange={e=>inputHandler(e)} className="form-control" placeholder="Doing Business As" defaultValue={formData.doingBusiness?formData.doingBusiness:''} />
                      <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"doingBusiness")}</div>
                    </div>
                  </div>
                  <GeoAddressComponent
                    history={props.history}
                    register={register}
                    errors={errors}
                    formData={formData}
                    inputHandler={inputHandler}
                    signupErrors={formDataErrors}
                    valueReset={valueReset}
                    tzStatus='NO'
                    lebal='Registered Business Address'
                  />
                  <div className="col-lg-12 t-pp">
                    <p>Must be a U.S. address. PO Boxes are not allowed.</p>
                  </div>
                  <div className="col-lg-6">
                      <label>Email*</label>
                      <div className="form-group">
                        <input type="email" name="email" onChange={e=>inputHandler(e)} className="form-control" placeholder="Email" defaultValue={formData.email?formData.email:''} ref={register({ required: 'Required',
                            pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Please enter a valid email address." }
                          })}
                        />
                        <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"email")}</div>
                      </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Business Phone Number*</label>
                    <div className="form-group phone_field">
                      <select className="form-control" name="phoneCode">
                        <option defaultValue="+1">+1</option>
                      </select>
                      
                      <NumberFormat
                        format="###-###-####"
                        name="phone_number"
                        //pattern="[0-9,-]{12}"
                        placeholder="Business Phone Number"
                        className="form-control"
                        value={formData.phone_number?formData.phone_number:''}
                        onChange={e =>{ inputHandler(e, 'phone_number'); setValue("phone_number", e.target.value);}}
                        ref={register(formData.phone_number===''?{name: "phone_number"}:'', 
                          {required: formData.phone_number===''?'Required':'',
                          pattern: {value: /^(?=.*\d)[\d-]{12}$/, message: "Phone number should be 10 digit number."}
                        } )}
                      />
                      {!formData.phone_number &&
                        <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"phone_number")}</div>
                      }
                      {formData.phone_number && formData.phone_number.replace(/[-]/g, '').trim().length!==10 && 
                        <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"phone_number")}</div>
                      }

                    </div>
                  </div>
                  <div className="col-lg-12">
                    <label>Industry Type*</label>
                    <div className="form-group">
                      <select className="form-control" id='industry' name="industry" onChange={(e)=>inputHandler(e)} ref={register({ required: 'Required' })} disabled={true}>
                        <option value="">Search..</option>
                        {mccList && mccList.map((val, int)=>{
                          return(<option value={val.id} 
                            selected={mccValue && mccValue===JSON.stringify(val.id)?true:false} key={int}>{val.category}</option>)
                        })}
                      </select>
                      <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"industry")}</div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <label>Business Website*</label>
                    <div className="form-group">
                      <input type="text" name="websiteUrl" onChange={e=>inputHandler(e)} className="form-control" placeholder="Business Website" defaultValue={formData.websiteUrl?formData.websiteUrl:''} ref={register({ required: 'Required', pattern: { value: /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,50})$/, message: "Please enter a valid website address." }})}
                      />
                      <small className="text-danger">If you do not own a business website, use the Recover domain: Recover.healthcare.</small>
                      <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"websiteUrl")}</div>
                      
                    </div>
                  </div>                
                  
                  <div className="col-lg-6 uploadfilesec">
                      <label>Photo ID-Front*</label>
                      <div className="form-group">
                        <input type="file" name="photoFront" accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc" onChange={(e)=>fileChangedHandler(e, "photoFront")} ref={register({required: 'Required'})} />
                        <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"photoFront")}</div>
                      </div>
                      {!userDetailStatus && formData.photoFrontPreview && 
                        <div className="fileDocs"><img src={formData.photoFrontPreview} alt="img"/></div>
                      }`
                  </div>                             
                  <div className="col-lg-6 uploadfilesec">
                    <label>Photo ID-Back*</label>
                    <div className="form-group">
                      <input type="file" name="photoBack" accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc" onChange={(e)=>fileChangedHandler(e, "photoBack")} ref={register({required: 'Required'})} />
                      <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"photoBack")}</div>
                    </div>
                    {!userDetailStatus && formData.photoBackPreview && 
                      <div className="fileDocs"><img src={formData.photoBackPreview} alt="img"/></div>
                    }
                  </div>
                  
                  
                    <div className="col-lg-12 t-pp">
                      <input type="reset" onClick={e=>setResetForm(e)} className="btn btn-primary rt" value="Clear"/>
                      <input type="submit" className="btn btn-primary" value="Save"/>
                    </div>
                                    
                </div>
              </form>
            }
            {companyStatus == true && personStatus == false &&
            
                <form onSubmit={handleSubmit(onPersonAccountUpdate)}>
                <div className="row">                  
                  
                  <div className="col-lg-12">
                    <label>Title*</label>
                    <div className="form-group">
                      <input type="text" name="title" className="form-control" placeholder="CEO, Manager, Partner" onChange={e=>inputHandler(e)} defaultValue={formData && formData.title?formData.title:''} ref={register({ required: 'Required' })} />
                      <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"title")}</div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>First Name*</label>
                    <div className="form-group">
                      <input type="text" name="first_name" className="form-control" placeholder="First Name" onChange={e=>inputHandler(e)} defaultValue={formData && formData.first_name?formData.first_name:''} ref={register({ required: 'Required' })} />
                      <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"first_name")}</div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Last Name*</label>
                    <div className="form-group">
                      <input type="text" name="last_name" className="form-control" placeholder="Last Name" onChange={e=>inputHandler(e)} defaultValue={formData.last_name?formData.last_name:''} ref={register({ required: 'Required' })} />
                      <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"last_name")}</div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Email*</label>
                    <div className="form-group">
                      <input type="email" name="email" onChange={e=>inputHandler(e)} className="form-control" placeholder="Email" defaultValue={formData.email?formData.email:''} ref={register({ required: 'Required',
                          pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Please enter a valid email address." }
                        })}
                      />
                      <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"email")}</div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Phone Number*</label>
                    <div className="form-group phone_field">
                      <select className="form-control" name="phoneCode">
                        <option defaultValue="+1">+1</option>
                      </select>
                      <NumberFormat
                        format="###-###-####"
                        name="phone_number2"
                        placeholder="Phone Number"
                        className="form-control"
                        value={formData.phone_number2?formData.phone_number2:''}
                        onChange={e =>{ inputHandler(e, 'phone_number2'); setValue("phone_number2", e.target.value);}}
                        ref={register(formData.phone_number2===''?{name: "phone_number2"}:'', 
                          {required: formData.phone_number2===''?'Required':'',
                          pattern: {value: /^(?=.*\d)[\d-]{12}$/, message: "Phone number should be 10 digit number."}
                        } )}
                      />
                      {!formData.phone_number2 &&
                        <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"phone_number2")}</div>
                      }
                      {formData.phone_number2 && formData.phone_number2.replace(/[-]/g, '').trim().length!==10 && 
                        <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"phone_number2")}</div>
                      }
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Date of Birth*</label>
                    <div className="form-group">
                      <DatePicker
                        name='dob'
                        selected={dobValue}
                        maxDate={moment().subtract(13, 'year')._d}
                        className="form-control"
                        dateFormat={appConstants.DateFormatNew}
                        placeholderText="Click to select a Date of Birth"
                        onChange={date => { setDobValue(date); setValue("dob", date);}}
                        ref={register({name: "dob"}, {required: dobValue?'':'Required'} )}
                        showMonthDropdown
                        useShortMonthInDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={5}
                      />
                      {!dobValue &&
                        (<div className="text-danger">{ShowFormErrors(errors,formDataErrors,"dob")}</div>)
                      }
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Social Security Number of Clinic Account Manager (SSN)*</label>
                    <div className="form-group">
                      <input type="text" name="personal_id_number" className="form-control" placeholder="Social Security Number" onChange={e=>inputHandler(e)} ref={register({ required: 'Required', pattern: { value: /^[0-9]{9}$/i, message: "Please enter a valid SSN." }})} />
                      <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"personal_id_number")}</div>
                    </div>
                  </div>
                  <div className="col-lg-6 uploadfilesec">
                    <label>Photo ID-Front*</label>
                    <div className="form-group">
                      <input type="file" name="photoFront" accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc" onChange={(e)=>fileChangedHandler(e, "photoFront")}  />
                      <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"photoFront")}</div>
                    </div>
                    {!userDetailStatus && formData.photoFrontPreview && 
                      <div className="fileDocs"><img src={formData.photoFrontPreview} alt="img"/></div>
                    }
                  </div>                             
                  <div className="col-lg-6 uploadfilesec">
                    <label>Photo ID-Back*</label>
                    <div className="form-group">
                      <input type="file" name="photoBack" accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc" onChange={(e)=>fileChangedHandler(e, "photoBack")}  />
                      <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"photoBack")}</div>
                    </div>
                    {!userDetailStatus && formData.photoBackPreview && 
                      <div className="fileDocs"><img src={formData.photoBackPreview} alt="img"/></div>
                    }
                  </div>
                  <div className="col-lg-12">
                    <label>Industry*</label>
                    <div className="form-group">
                      <select className="form-control" id='industryId' name="industry" onChange={(e)=>inputHandler(e)} ref={register({ required: 'Required' })} disabled={true}>
                        <option value="">Search..</option>
                        {mccList && mccList.map((val, int)=>{
                          return(<option value={val.id} 
                            selected={mccValue && mccValue===JSON.stringify(val.id)?true:false} key={int}>{val.category}</option>)
                        })}
                      </select>
                      <div className="text-danger">{ShowFormErrors(errors,formDataErrors,"industry")}</div>
                    </div>
                  </div>

                  <GeoAddressComponent
                    history={props.history}
                    register={register}
                    errors={errors}
                    formData={formData}
                    inputHandler={inputHandler}
                    signupErrors={formDataErrors}
                    valueReset={valueReset}
                    tzStatus='NO'
                  />
                  <div className="col-lg-12 t-pp">
                    <p>Must be a U.S. address. PO Boxes are not allowed.</p>
                  </div>
                  {!userDetailStatus &&
                    <div className="col-lg-12 t-pp">
                      <input type="reset" onClick={e=>setResetForm(e)} className="btn btn-primary rt" value="Clear"/>
                      <input type="submit" className="btn btn-primary" value="Save"/>
                    </div>
                  }
                  {userDetailStatus && 
                    <div className="col-lg-12 t-pp">
                      <Link to="#" type="button" onClick={e=>deletePayoutAccount(e)} className="btn btn-primary">Remove Account</Link>
                    </div>
                  }
                </div>
              </form>

            }
            </div> 
            <div className="spinner" style={{display:spinner?'block':'none'}}>
                <img src="/static/img/spinner.gif" alt='loader'/>
            </div>
          </div>
  );
}
export default withLocalize(CompanyAccount);
