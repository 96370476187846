import React,{useState,useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import { withLocalize, Translate } from 'react-localize-redux';
import SideMenu from '../../Components/DashboardPage/SideMenu';
import {Col, Button} from 'reactstrap';
import moment from 'moment';
import {getFutureAppointments, appointmentStatusAction, checkPTcancelAppointment, userLogoutAction, getPendingAppointmentsCount} from '../../Actions/User.action';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {appConstants, toUTC, getLocalDate} from '../../../_Constants/app.constants';
import toastr from "../../../_Common/toaster";
import { confirmAlert } from 'react-confirm-alert';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import {BootstrapTable,TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

const FutureAppointmentList = ({history}) => {
  const [appointmentList, setAppointmentList] = useState([]);
  const [toValue,setToValue] = useState('');
  const [fromValue,setFromValue] = useState('');
  const [name, setName] = useState('');
  const [toError, setToError] = useState('');
  const [userType, setUserType] = useState(localStorage.getItem("userType"));
  const [spinner,setSpinner] = useState(false);

  const PendingAppointmentsCount=async()=>{	
    let datetime = toUTC(moment().format('YYYY-MM-DD HH:mm:ss'));
    let data = await getPendingAppointmentsCount({datetime:datetime});            
    sessionStorage.penddingCount = data.count;
}
  
 const getAppointmentList = async(search='')=>{
    if(search == 'reset'){
        setFromValue('');
        setToValue('');
        setName('');
        setToError('');
    }
    
    if(fromValue && fromValue !== '' && toValue === ''){
        setToError('Select to date!');
    }else{
        setToError('');
        setSpinner(true);
       
        let datetime = toUTC(moment().format('YYYY-MM-DD HH:mm:ss'));
       
        let list = await getFutureAppointments({startDate:fromValue && search != 'reset'?toUTC(getLocalDate(fromValue)):'',endDate:toValue && search != 'reset'?toUTC(moment(getLocalDate(toValue),'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')):'', name: search != 'reset'?name:'', datetime:datetime}); 

        list = list.data;
        list.map(item=>{
            item.date = moment(item.startTime).format('YYYY-MM-DD');
            item.startTime = moment(item.startTime).format('hh:mm A');
            item.endTime = moment(item.endTime).format('hh:mm A');
            return item;
        })
        setAppointmentList(list); 
        setSpinner(false); 
        
       if(list.length>0){        
        PendingAppointmentsCount();
       }else{
        sessionStorage.penddingCount = 0;
       }                
    } 
  }

  
  
  const action=(cell,row)=>{
    
    let appDate = moment(row.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    return ( 
      <Col>
        { (userType != 'PATIENT') && row.status === 'PENDING' && moment().add(10, 'minutes').format('x') <= moment(row.startTimeDate).format('x') &&
          <div><Button color="success" onClick={event => changeStatus(event, row,'ACCEPTED')} title="Accept">ACCEPT</Button>&nbsp;
          <Button color="danger" onClick={event => changeStatus(event, row,'REJECT')} title="Reject"><b>REJECT</b></Button></div>
        }
        { userType==='PATIENT' && row.status === 'PENDING' &&
          <div>PENDING</div>
        }
        {row.status === 'ACCEPTED' &&
            <div>
              { row.session === true && localStorage.getItem("userType") !=='CLINIC' &&
                  <span><Link to={{ pathname: '/session', state: row}} className="btn-fill btn-wd btn btn-info" title={localStorage.getItem("userType")==='PT'?'Start Session':'Join Session'}>{localStorage.getItem("userType")==='PT'?'Start':'Join'}</Link>&nbsp;</span>
              }
              { row.session === true && localStorage.getItem("userType") ==='CLINIC' &&
                  <span>Session in progress</span>
              }
              { ((row.session === false || !row.session) && moment().add(10, 'minutes').format('x') <= moment(appDate+" "+row.startTime, 'YYYY-MM-DD hh:mm A').format('x')) &&
              <Button color="danger" onClick={event => checkCancelAppointment(event, row,'CANCEL')} title="Cancel"><b>CANCEL</b></Button>
              }                
          </div>
        }
      </Col>

    )
  }
    
  const onLogout = async () =>{
   // e.preventDefault();
    let res = await userLogoutAction();
    if(res.success){
      localStorage.clear();
      toastr.show({message: 'Logout successfully',intent: "success"});
      history.push('/');
    }else{
     if(res.msg){
        toastr.show({message: res.msg,intent: "danger"});
      }
    }
  }

  const checkCancelAppointment = async(event, row, status)=>{  
      
      let today = moment();
      
      let appointmentdate = moment(row.date+" "+row.startTime, 'YYYY-MM-DD hh:mm A');
      
      let duration = moment.duration(appointmentdate.diff(today));
      let hours = duration.asHours();
              
      if(hours<24 || userType !='PATIENT'){
          let reponse = await checkPTcancelAppointment({appointmentId:row._id});
          if(reponse.status === true){
            
            if(userType == 'PT'){

              var msg = "If you cancel appointment, will be suspended your account. Are you sure, you want to do this?";
            }else if(userType == 'CLINIC'){

              var msg = "If you cancel appointment, will be suspended PT account. Are you sure, you want to do this?";
            }else{
              var msg = "If you cancel appointment, will be charged 50% of the booking fee. Are you sure, you want to do this?";
            }
            changeStatus(event, row, status, msg);
          }else{
            changeStatus(event, row, status);
          }
      }else{
        changeStatus(event, row, status);
      }
  }
  const changeStatus=(event, row, status, msg='')=>{  

    let confirm = '';
    if(status && status==='ACCEPTED'){
      confirm = 'accept';
    }else if(status && status==='REJECT'){
      confirm = 'reject';
    }else if(status && status==='CANCEL'){
      confirm = 'cancel';
    }
    confirmAlert({
      title: 'Confirm to '+confirm,
      message: msg!=''?msg:'Are you sure, you want to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: async() => {
                setSpinner(true);
                let reponse = await appointmentStatusAction({appointmentId:row._id, status:status});  
                
                if(reponse.success){  setSpinner(false);
                    if(msg != '' && userType == 'PT'){

                      toastr.show({message: "Appointment "+(status=='CANCEL'?'Canceled':(status=='REJECT'?'Rejected':'Accepted'))+" Successfully!",intent: "success"}); 
                      onLogout();
                    }else{
                        getAppointmentList();
                        toastr.show({message: "Appointment "+(status=='CANCEL'?'Canceled':(status=='REJECT'?'Rejected':'Accepted'))+" Successfully!",intent: "success"}); 
                    }             
                    
                }
            }
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const nameDetails=(cell,row)=>{     
    let clinicName = (userType==='PATIENT' && row.clinicName && row.clinicName != '')?'('+row.clinicName+')':'';
return (<span>{row.ptName}{ clinicName!=='' && <br></br>}{clinicName}</span>);
}

const dateTimeDetails=(cell,row)=>{   
  return (<span>{moment(row.date, 'YYYY-MM-DD').format(appConstants.DATEFORMAT)},<br></br>{row.startTime+'-'}{row.endTime},<br></br>({row.duration}Min)</span>);
  }

  const onProfilePic=(cell,row, enumObject, index)=>{
    
    const intials = userType==='PATIENT'?row.ptName.split(' ').map(name => name[0]).join('').toUpperCase() : row.paName.split(' ').map(name => name[0]).join('').toUpperCase();
   
    if(row.ptImg && row.ptImg!='' && userType==='PATIENT'){
        return (<img className="profilepic" src={`${process.env.REACT_APP_STATIC_S3_URL+row.ptImg}`} alt={intials}/>);
    }else if(row.paImg && row.paImg!='' && userType!=='PATIENT'){
        return (<img className="profilepic" src={`${process.env.REACT_APP_STATIC_S3_URL+row.paImg}`} alt={intials}/>);
    }else{
        return (<div className="profilepic"> {intials} </div>);
    }
  } 

  useEffect(()=>{
      getAppointmentList();    

      let interval = setInterval(() => {
          setAppointmentList(appointmentList=>{
              let appList = appointmentList.map((key,i)=>{
                  
                    let appDate = moment(key.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
                    key.session = false;
                    
                    if(moment().add(10, 'minutes').format('x') >= moment(appDate+" "+key.startTime, 'YYYY-MM-DD hh:mm A').format('x') && moment().add(1, 'minutes').format('x') <= moment(appDate+" "+key.endTime, 'YYYY-MM-DD hh:mm A').format('x') && key.status === 'ACCEPTED'){
                        key.session = true;
                    }
                    return key;
              });
                return appList;
          });
        }, 5000);
      
      return () => clearInterval(interval);
  },[]);

  return (
  <div>
    <MetaTags className="bg-rr">
      <title>Future Appointments</title>
      <meta name="description" content="Recover" />
      <meta name="keywords" content="Recover" />
    </MetaTags> 
    <section className="dash_section pad-reggis future_appoinment_page">
      <BreadcrumbsComp bcChanges={[{path: '/future-appointments', breadcrumb: 'Future Appointments'}]} />
      
      <div className="container">
        <div className="row">
          <div className="col-md-3 left_dashboard scrollbar2 leftContainer">
            <SideMenu/>
          </div>
          <div className="col-md-9 right_dashboard scrollbar3 rightContainer ">
            <div className="content-setion ">
              <div className="section_title m-25"><h3>Future Appointments</h3></div>
              <div className="section_title m-25 error">For a better video session experience please use Chrome/Firefox browsers on Android/Windows OS, and Chrome/Safari browsers on iOS devices/Mac Machine.</div>
              <div className="search-availabilty gray_boxx">
                <div className="row">
                  <div className="col-md-3">
                    <label>Search</label>
                    <div className="to-from">
                      <input type="text" name="name" onChange={e =>setName(e.target.value)} placeholder="Type text..." value={name} />
                    </div>
                  </div>
                  <div className="col-md-3">              
                    <label>From</label>
                    <div className="to-from">
                      <DatePicker
                        minDate={new Date()}
                        onChange={date =>(setFromValue(date?date:''),setToValue(date && toValue>=date?toValue:''))}    
                        selected={fromValue}     
                        placeholderText={'From date'}       
                        dateFormat={appConstants.DateFormatNew}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>To</label>
                    <div className="to-from">
                      <DatePicker
                        minDate={fromValue?fromValue:new Date()}           
                        onChange={date =>setToValue((fromValue?(date?date:''):''),setToError(''))} 
                        selected={toValue>=fromValue?toValue:''} 
                        placeholderText={'To date'}               
                        dateFormat={appConstants.DateFormatNew}
                      />
                    </div>
                    <small className="btn_sec text-center error">{toValue==''?toError:''}</small>
                  </div>
                  <div className="col-md-3">
                    <div className="to-from">
                        <button type="button" className="btn btn-primary" onClick={e=>getAppointmentList()}>Search</button>
                    </div>
                    <div className="to-from">
                        <button type="button" className="btn btn-primary" onClick={e=>getAppointmentList('reset')}>reset</button>
                    </div>
                  </div>
                </div>
              </div>
              <BootstrapTable
                data={ appointmentList }
                pagination>
                <TableHeaderColumn dataField='_id' hidden={true} isKey>Id</TableHeaderColumn>

                {userType!=='CLINIC' && 
                <TableHeaderColumn width='100' dataField='' dataFormat={onProfilePic}>Profile Pic</TableHeaderColumn>
                }

                <TableHeaderColumn dataField='orderId' thStyle={{ width: '120px' }} tdStyle={{ width: '120px' }}>Appointment<br></br>ID</TableHeaderColumn>

                <TableHeaderColumn dataField='' dataFormat={dateTimeDetails} thStyle={{ width: '125px' }} tdStyle={{ width: '125px' }}>Appointment<br></br>Date & Time</TableHeaderColumn>

                 {userType==='CLINIC' && 
                <TableHeaderColumn width='100' dataField='' dataFormat={onProfilePic}>Client’s<br></br> Pic</TableHeaderColumn>
                }

                {userType!=='PATIENT' && 
                  <TableHeaderColumn dataField='paName' thStyle={{ width: '170px' }} tdStyle={{ width: '170px' }}>Client Name</TableHeaderColumn>
                }
                              
                {userType!=='PT' && 
                  <TableHeaderColumn dataField='' dataFormat={nameDetails} thStyle={{ width: '170px' }} tdStyle={{ width: '170px' }}>Provider Name <br></br>{userType==='PATIENT'?'(Clinic Name)':''}</TableHeaderColumn>
                }
                               
                <TableHeaderColumn dataField='' dataFormat={action} thStyle={{ width: '215px' }} tdStyle={{ width: '215px' }}>Action</TableHeaderColumn>
              </BootstrapTable>              
            </div>
          </div>              
        </div>
      </div>
    </section>
    <div className="spinner" style={{display:spinner?'block':'none'}}>
        <img src="/static/img/spinner.gif" alt='loader'/>
      </div>
  </div>
  );
}
export default withLocalize(withRouter(FutureAppointmentList));
