
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { providerList} from '../../Actions/User.action';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';

const FindSpecialist = ({ history }) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
 
    const [adminProviderData, setAdminProviderData] = useState({});
  const providerListttt = async () => {
    let AdminProviderData = await providerList();
    // console.log("famousProggggggggggviderData",AdminProviderData.data[0].price_for_60_min)
    // setTotalSize(AdminProviderData.count?AdminProviderData.count:AdminProviderData.data.length);  
    setAdminProviderData(AdminProviderData);
  }


  useEffect(() => {
    providerListttt();
  }, []);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue)
    console.log("searchValue",searchValue)

    if (searchInput !== '') {
     console.log("adminProviderData.data",adminProviderData.data)
      const filteredData = adminProviderData.data.filter((item) => {
          
            return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
        })
        setFilteredResults(filteredData)
        console.log("filteredData",filteredData)
    }
    else{
        setFilteredResults(adminProviderData.data)
    }
}

  return (
    <div>
      <section className="pad-reggis">
        <BreadcrumbsComp />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="col section_title text-center">
                <h3>Find A Specialist </h3>
                <div className="decor">
                </div>
                {/* <input type="text" onChange={(e) => setSearchText(e.target.value)}/> */}
                <div className='findSpecialist'>
                <div><label className='rajaram'>Find A Specialist</label></div> 
                <input icon='search'
                className='SerchBar'
                placeholder='Find A Specialist'
                onChange={(e) => searchItems(e.target.value)}
            />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
      {searchInput.length > 1 ? (
      filteredResults&& filteredResults.map((item, index)=>(
        <div className="container"key={index}>
          <div className='provider-div'>
          <div className='row mb-3'>
            <div className='col-md-3'>
              <div className='provider-left-div'>
                <div className='imgprofile'>
                  <img
                              src={`${process.env.REACT_APP_STATIC_S3_URL + item.profileImg}`}
                              alt="No Profile Pic"
                              onError={(e) => {
                                e.target.src = "/static/img/providerimg.png"
                                e.target.style = ''
                              }}
                            />
                </div>
              </div>
            </div>
            <div className='col-md-9'>
              <div className='provider-right-div find-specilist '>
                <div className='mb-3'>
                  <h4>{item.fullName}</h4>
                  <p className='mb-2'>{item.title}</p>
                </div>
                <div className='row mb-3'>
                  <div className='col-md-12'>
                    <table >
                      <tr>
                        <th>Education</th>
                        <td>{item.education}</td>
                      </tr>
                      <tr>
                        <th>Bio</th>
                        <td>{item.bio}</td>
                      </tr>
                      <tr>
                        <th>License Number</th>
                        <td>{item.licenseNumber}</td>
                      </tr>
                      <tr>
                        <th>charge clients for 30 minute sessions</th>
                        <td>{item.price_for_30_min}</td>
                        {/* <td>{item?.map(item => <div>{item?.price_for_30_min}</div>)}</td> */}

                      </tr>
                      <tr>
                        <th>charge clients for 60 minute sessions</th>
                        <td>{item.price_for_60_min}</td>
                      </tr>
                    </table>
                  </div>
                  {/* <div className='col-md-5 call-box' >
                    <h3 className='text-center'>Call {item.contact}</h3>
                    <div className="text-center">
                      <Link className="btn btn-primary "  onClick={() => openInNewTab(item.webUrl)} >Visit Website</Link>
                    </div>

                  </div> */}
                </div>
                <div className='specicility-box'>
                  <h3>Specialization</h3>
                  <ul>
                  <div>
        {
          item.specialization?.map(function(ttttt, index) {
            return <span key={`demo_snap_${index}`}>{ (index ? ', ' : '') + ttttt.specializationName }</span>;
          })
        }
      </div>
                   
                  </ul>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
         ))
      ) : (
        adminProviderData && adminProviderData.data && adminProviderData.data.map((item, index)=>(
          <div className="container" key={index}>
            <div className='provider-div'>
            <div className='row mb-3'>
              <div className='col-md-3'>
                <div className='provider-left-div'>
                  <div className='imgprofile'>
                    {/* <img src="/static/img/providerimg.png" alt="" /> */}
                    <img
                                src={`${process.env.REACT_APP_STATIC_S3_URL + item.profileImg}`}
                                alt="No Profile Pic"
                                onError={(e) => {
                                  e.target.src = "/static/img/providerimg.png"
                                  e.target.style = ''
                                }}
                              />
                  </div>
                  <div className='imgprofiledata'>
                    <h3 className='text-white'>{item.providerName}</h3>
                    <p className='text-white'>{item.title} </p>
                    <p className='text-white'>{item.clinicName}</p>	
                  </div>
                </div>
              </div>
              <div className='col-md-9'>
                <div className='provider-right-div find-specilist'>
                  <div className='mb-3'>
                    <h4>{item.fullName}</h4>
                    <p className='mb-2'>{item.title}</p>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-md-12'>
                      <table >
                        <tr>
                          <th>Credentials</th>
                          <td className='provider-right-div-tdd'>{item.education}</td>
                        </tr>
                        <tr>
                          <th>Bio</th>
                          <td className='provider-right-div-tdd' >{item.bio}</td> 
                        </tr>
                        <tr>
                        <th>License Number</th>
                        <td>{item.licenseNumber}</td>
                      </tr>
                      <tr>
                        <th>Price for 30 minute session</th>
                        <td>${item.price_for_30_min}</td>
                        {/* <td>{item?.map(item => <div>{item?.price_for_30_min}</div>)}</td> */}
                        {/* {console.log("ppppppppppppppppppppppp",item?.price_for_30_min)} */}
                      </tr>
                      <tr>
                        <th>Price for 60 minute session</th>
                        <td>${item.price_for_60_min}</td>
                      </tr>
                      </table>
                    </div>
                  </div>
                  <div className='specicility-box'>
                    <h3>Specialization</h3>
                    <ul>
                    <div>
          {
            item.specialization?.map(function(ttttt, index) {
              return <span key={`demo_snap_${index}`}>{ (index ? ', ' : '') + ttttt.specializationName }</span>;
            })
          }
        </div>
                     
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
           ))
      )}
      </section>
    </div>
  );
}
export default withRouter(FindSpecialist);

