import Axios from "axios";
import {postRequest, getRequest} from "../../_Api/helper"

export const userRegistrationAction = (data) => {
    let formData = new FormData();
    for(let x in data){
         formData.append(x, data[x]);
    }
	if(data.userType==='CLINIC'){
    	return postRequest('registerClinic',formData).then(res=>{return res.data});
	}
    return postRequest('register',formData).then(res=>{return res.data});
}

export const userLoginAction = (data) => {
    return postRequest('login',data).then(res=>{return res.data});
}

export const userLogoutAction = (data) => {
    return postRequest('logout',data).then(res=>{return res.data});
}

export const forgotPasswordAction = (data) => {
	return postRequest('forgotPassword',data).then(res=>{return res.data});
}

export const resetPasswordAction = (data) => {
    return postRequest('resetPassword',data).then(res=>{return res.data});
}

export const changePasswordAction = (data) => {
    return postRequest('changePassword',data).then(res=>{return res.data});
}

export const getClinicListAction = (data) => {
    return getRequest('clinicList',data).then(res=>{return res.data});
}

export const getUserProfileAction = (data) => {
    return getRequest('getUserProfile',data).then(res=>{return res.data});
}

export const userEnquiryAction = (data) => {
    return postRequest('userEnquiry',data).then(res=>{return res.data});
}

export const requestProviderAppointment = (data) => {
    return postRequest('requestProviderAppointment',data).then(res=>{return res.data});
}

export const getPTListAction = (data) => {
    return getRequest('getPTList',data).then(res=>{return res.data});
}

export const getPendingAppointmentsCount = (data) => {
    return postRequest('getPendingAppointmentsCount',data).then(res=>{return res.data});
}

export const editProfileAction = (data) => {
    let formData = new FormData();
    for(let x in data){
        if(x == 'image'){
            let k = 0;
            let image = data[x];
            for(let j in image){
                formData.append('image'+(++k), image[j]);
            }
        }else{
            formData.append(x, data[x]);
        }

    }
    return postRequest('editProfile',formData).then(res=>{return res.data});
}

export const deletePaDocsAction = (data) => {
    return postRequest('deletePaDocs',data).then(res=>{return res.data});
}

export const getBlogListAction = (data) => {
    return getRequest('getBlogList',data).then(res=>{return res.data});
}

export const getBlogDetailAction = (data) => {
    return getRequest('getBlogDetail',data).then(res=>{return res.data});
}


//availability actions
export const availabilitySaveAction = (data) => {
    return postRequest('addEditAvailability',data).then(res=>{return res.data});
}

export const deleteAvailabilityAction = (data) => {
    return getRequest('deleteAvailability',data).then(res=>{return res.data});
}


export const getAvailabilityList = (data) => {
    return getRequest('getAvailabilityList',data).then(res=>{return res.data});
}
export const getSettingData = (data) => {
    return getRequest('getSettingData',data).then(res=>{return res.data});
}

export const getCalanderAvailability = (data) => {
    return postRequest('getCalanderAvailability',data).then(res=>{return res.data});
}

export const getPTAvailabilityList = (data) => {
    return postRequest('getPTDate',data).then(res=>{return res.data});
}
export const getNotAvailableProviders = (data) => {
    return postRequest('getNotAvailableProviders',data).then(res=>{return res.data});
}
export const getAppointmentPrices = (data) => {
    return getRequest('getAppointmentPrices',data).then(res=>{return res.data});
}

export const saveBookingAction = (data) => {
    return postRequest('bookAppointment',data).then(res=>{return res.data});
}

export const appointmentStatusAction = (data) => {
    return postRequest('appointmentAction',data).then(res=>{return res.data});
}

export const appointmentStartEndAction = (data) => {
    return postRequest('appointmentStartEndAction',data).then(res=>{return res.data});
}

export const checkPTcancelAppointment = (data) => {
    return postRequest('checkPTcancelAppointment',data).then(res=>{return res.data});
}
export const getFutureAppointments = (data) => {
    return postRequest('getFutureAppointments',data).then(res=>{return res.data});
}

export const getSinglePTDate = (data) => {
    return postRequest('getSinglePTDate',data).then(res=>{return res.data});
}

export const getImageDataListAction = (data) => {
    return getRequest('data/tempData.json',data).then(res=>{return res.data});
    return Axios.get('data/tempData.json')
}
export const addSpecialization = (data) => {
    return postRequest('addSpecialization',data).then(res=>{return res.data});
}
export const specializationList = (data) => {
    return postRequest('specializationList',data).then(res=>{return res.data});
}

export const getAppointmentHistory = (data) => {
    return postRequest('getHistoryAppointments',data).then(res=>{return res.data});
}

export const getPaymentTransactionsList = (data) => {
    return postRequest('getPaymentTransactionsList',data).then(res=>{return res.data});
}

export const addSopNote = (data) => {
    return postRequest('addSopNote',data).then(res=>{return res.data});
}

export const getClinicPTListAction = (data) => {
    return getRequest('getClinicPTList',data).then(res=>{return res.data});
}

export const getFeedbackListAction = (data) => {
    return getRequest('getFeedbackList',data).then(res=>{return res.data});
}

export const famousProviderList = (data) => {
    return postRequest('famousProviderList',data).then(res=>{return res.data});
}

export const providerList = (data) => {
    return postRequest('provider-list',data).then(res=>{return res.data});
}

export const providerListSpecific = async (data) => {
    const newData = {
        search : data
    }
    return await postRequest('provider-list',newData).then(res=>{return res.data});
}

export const getFeedbackDetailAction = (data) => {
    return getRequest('getFeedbackDetail',data).then(res=>{return res.data});
}

export const addPaymentCardAction = (data) => {
    return postRequest('addPaymentCard',data).then(res=>{return res.data});
}
export const getPayCartListAction = (data) => {
    return getRequest('getPaymentCartList',data).then(res=>{return res.data});
}
export const setDefaultCardAction = (data) => {
    return postRequest('setDefaultCard',data).then(res=>{return res.data});
}
export const addAccountAction = (data) => {
    return postRequest('addAccount',data).then(res=>{return res.data});
}
export const getAccountListAction = (data) => {
    return getRequest('getAccountList',data).then(res=>{return res.data});
}
export const merchantCategoryCodeListAction = (data) => {
    return getRequest('merchantCategoryCodeList',data).then(res=>{return res.data});
}
export const updateAccountAction = (data) => {
    return postRequest('payoutAccountUpdate',data).then(res=>{return res.data});
}
export const companyAccountUpdateAction = (data) => {
    return postRequest('payoutCompanyAccountUpdate',data).then(res=>{return res.data});
}
export const updateAccountPersonAction = (data) => {
    return postRequest('updateAccountPerson',data).then(res=>{return res.data});
}
export const payoutFileUploadsAction = (data) => {
    let formData = new FormData();
    formData.append("idProofImage",data);
    return postRequest('payoutFileUploads',formData).then(res=>{return res.data});
}
export const clinicInActivePtAction = (data) => {
    return getRequest('clinicInActivePt',data).then(res=>{return res.data});
}
export const deleteAccountAction = (data) => {
    return getRequest('deletePayoutAccount',data).then(res=>{return res.data});
}
export const getMedicalHistoryAction = (data) => {
    return getRequest('getMedicalHistory',data).then(res=>{return res.data});
}
export const addMedicalHistoryAction = (data) => {
    return postRequest('addMedicalHistory',data).then(res=>{return res.data});
}
export const getSopNoteDataAction = (data) => {
    return getRequest('getSopNoteData',data).then(res=>{return res.data});
}
export const resetSopNoteAction = (data) => {
    return getRequest('resetSopNoteData',data).then(res=>{return res.data});
}
export const getPreviousDataAction = (data) => {
    return getRequest('getPreviousData',data).then(res=>{return res.data});
}
export const deletePaymentCartAction = (data) => {
    return getRequest('deletePaymentCart',data).then(res=>{return res.data});
}

export const getTwilioToken = (data) => {
    return getRequest('token',data).then(res=>{return res.data});
}

export const payoutTransfer = (data) => {
    return postRequest('payoutTransfer',data).then(res=>{return res.data});
}