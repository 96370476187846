import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormGroup, Label, Input } from "reactstrap";
import { FormError, ShowFormErrors } from "../../../../_Common/formError";
import toastr from "../../../../_Common/toaster";
import {
  getUserProfileAction,
  editProfileAction,
  specializationList,
} from "../../../Actions/User.action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  appConstants,
  checkExtFunction,
  setSessionValue,
  toUTC,
} from "../../../../_Constants/app.constants";
import GeoAddressComponent from "../../../../_Common/GeoAddressManage";
import { getSettingData } from "../../../Actions/User.action";
import { getSetPriceforEntitiesAction } from "../../../../SuperAdmin/actions/Clinic.action";

import renderHTML from "react-render-html";
import moment from "moment";
import $ from "jquery";
import { Dialog } from "@blueprintjs/core";
import ChangePassword from "../ChangePassword/index";
import NumberFormat from "react-number-format";
import SideMenu from "../SideMenu";
import Multiselect from 'multiselect-react-dropdown';
// import { getFeedbackListAction, getImageDataListAction, providerList,specializationList} from '../../../Actions/User.action';

const ProfilePage = ({ history, cptId }) => {
  //cptId: For Edit clinic Pt with respect to ptId
  const [signupForm, setSignupForm] = useState({specialization:[]});
  const [specializationValue, setSpecializationValue] = useState([]);
  const [signupErrors, setSignupErrors] = useState({});
  const [isChangePassword, setIsChangePassword] = useState(false);
  const { register, watch, handleSubmit, setValue, errors } = useForm();
  const [dobValue, setDobValue] = useState();
  const [licenseDate, setLicenseDate] = useState();
  const [insuranceDate, setInsuranceDate] = useState();
  const [EditStatus, setEditStatus] = useState(false);
  const [dobDisableStatus, setDobDisableStatus] = useState(false);
  const [licenseDisableStatus, setLicenseDisableStatus] = useState(false);
  const [insuranceDisableStatus, setInsuranceDisableStatus] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [insuranceDateValidation, setInsuranceDateValidation] = useState(false);
  const [licenseDateValidation, setLicenseDateValidation] = useState(false);
	const [isSelected, setIsSelected] = useState(true);

  const getUserProfileDetil = async (status) => {
    let userDetail = await getUserProfileAction(cptId ? { userId: cptId } : {});
    if (
      status === "UPDATE_SESSION" &&
      userDetail &&
      userDetail.data &&
      !cptId
    ) {
      setSessionValue(userDetail.data);
      window.location.reload();
    }
    setSpecializationValue(userDetail.data.specialization)
    userDetail.data.time_zone =
      userDetail.data.timeZone && userDetail.data.timeZone.text
        ? userDetail.data.timeZone.text
        : "";
    userDetail.data.timeZone =
      userDetail.data.timeZone && userDetail.data.timeZone
        ? JSON.stringify(userDetail.data.timeZone)
        : "";
    setSignupForm(userDetail.data);

    if (userDetail && userDetail.data !== "") {
      let dobDateValue = userDetail.data.dob
        ? new Date(userDetail.data.dob)
        : "";
      let insuranceDateValue = userDetail.data.insuranceExpDate
        ? new Date(moment(userDetail.data.insuranceExpDate))
        : "";
      let licenseDateValue = userDetail.data.licenseExpDate
        ? new Date(moment(userDetail.data.licenseExpDate))
        : "";
      setDobValue(dobDateValue);
      setInsuranceDate(insuranceDateValue);
      setLicenseDate(licenseDateValue);
    }
  };

  const inputHandler = (e, field) => {
    let signupFormData = signupForm;
    signupFormData[field] = e.target.value;
    setSignupForm({ ...signupForm, ...signupFormData });
  };

//   const [clinicList2, setClinicList2] = useState([]);
//     const specializationListtt3 = async()=>{
//       let dataList2 = await getSettingData(); 
//       console.log("dataListggggggggggggeeeeeee",dataList2)
//       setClinicList2(dataList2.data);  
//   }
//   useEffect(()=>{
//     specializationListtt3();
      
//   },[]);

  const [imageData, setImageData] = useState([]);

useEffect(()=>{
    const specialList = async()=>{
		const getspecializationName=[];
      let dataList = await specializationList(); 
      for(let i= 0; i < dataList.data.length; i++)
	  {
		getspecializationName.push({"specializationName":dataList.data[i].name, "specializationId": dataList.data[i]._id})
	  }
    console.warn("ddddddddddvsvsvsv",getspecializationName)
	  setImageData(getspecializationName);
  }
  specialList();
},[]);

  const inputSelectHandler = (value,field)=>{
		setIsSelected(true)
		let signupFormData = signupForm;
		signupFormData[field] = value;
    	setSignupForm({...signupForm,...signupFormData});
	}

  const radioHandler = (e, field) => {
    let signupFormData = signupForm;
    if (
      field === "phone" &&
      e.target.value.replace(/[-]/g, "").trim().length === 10
    ) {
      $('input[name="phone"]').removeClass("requiredClass");
    }
    signupFormData[e.target.name] = field;
    setSignupForm({ ...signupForm, ...signupFormData });
  };

  const fileChangedHandler = (event, elename) => {
    event.preventDefault();
    if (!event.target.files[0] && event.target.files[0] === undefined) {
      return false;
    }
    let signupFormData = signupForm;
    let file = event.target.files[0];
    var fileName = file && file.name ? file.name : "";
    let extensions = fileName
      .substring(fileName.lastIndexOf(".") + 1)
      .toLowerCase();
    if (
      extensions === "bmp" ||
      extensions === "jpg" ||
      extensions === "png" ||
      extensions === "jpeg"
    ) {
      signupFormData[elename + "Preview"] = URL.createObjectURL(
        event.target.files[0]
      );
      signupFormData[elename] = event.target.files[0];
      setSignupForm({ ...signupForm, ...signupFormData });
    } else if (extensions === "docx" || extensions === "doc") {
      signupFormData[elename + "Preview"] = "/static/img/docIcon.png";
      signupFormData[elename] = event.target.files[0];
      setSignupForm({ ...signupForm, ...signupFormData });
    } else if (extensions === "pdf") {
      signupFormData[elename + "Preview"] = "/static/img/pdfIcon.png";
      signupFormData[elename] = event.target.files[0];
      setSignupForm({ ...signupForm, ...signupFormData });
    } else {
      let err = [{ msg: "Extensions doesn't match", param: elename }];
      setSignupErrors(FormError(err));
    }
  };

  const onFormSubmit = async () => {
    if(isSelected) {
    setSpinner(true);
    signupForm["phone"] = signupForm["phone"].replace(/\D/g, "");
    signupForm['specialization'] = JSON.stringify(signupForm['specialization'])
    signupForm["insuranceExpDate"] = insuranceDate
      ? toUTC(
        moment(insuranceDate, appConstants.DateFormatNew).format("YYYY-MM-DD")
        )
      : "";
      signupForm["licenseExpDate"] = licenseDate
      ? toUTC(
          moment(licenseDate, appConstants.DateFormatNew).format("YYYY-MM-DD")
        )
      : "";
    signupForm["dob"] = dobValue ? toUTC(dobValue) : "";
    signupForm["timeZone"] = signupForm["timeZone"];
    let res = {};
    let formErrors = {};
    if (cptId && cptId !== "") {
      //This condition use For Edit Clinic Pt
      signupForm["userId"] = cptId;
    }
    
    console.warn("qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq",signupForm)
    res = await editProfileAction(signupForm);
    console.warn("qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq",res)
    if (res.err && res.err.length > 0) {
      formErrors = FormError(res.err);
    }
    setSignupErrors(formErrors);
    if (res.success) {
      getUserProfileDetil("UPDATE_SESSION");
      $("input, select").prop("disabled", true);
      setEditStatus(false);
      setSpinner(false);
			setIsSelected(false);

      if (cptId && cptId !== "") {
        //This condition use For Edit and redirect to Clinic Pt's
        history.push("/clinic-pt");
      }
      toastr.show({ message: res.msg, intent: "success" });
    } else if (res.success === false) {
      setSpinner(false);
      toastr.show({ message: res.msg, intent: "danger" });
    }
    }
  };

  const onEditClick = (e, status) => {
    e.preventDefault();
    setEditStatus(!status);
    getUserProfileDetil();

    setDobDisableStatus(false);
    setLicenseDisableStatus(false);
    setInsuranceDisableStatus(false);
    $("input, select").prop("disabled", status);
    $("input[name=address]").prop("disabled", true);
    $("input[name=city]").prop("disabled", true);
    $("input[name=state]").prop("disabled", true);
    $("input[name=zipCode]").prop("disabled", true);
    $("input[name=email]").prop("disabled", true);

    var currentDateStr = moment().format("x");
    var insuranceDateMinus = moment(insuranceDate).add(-1, "M").format("x");
    if (insuranceDateMinus > currentDateStr) {
      setInsuranceDisableStatus(true);
      $('input[name="insuranceExpDate"]').prop("disabled", true);
    }
    var licenseDateMinus = moment(licenseDate).add(-1, "M").format("x");
    if (licenseDateMinus > currentDateStr) {
      setLicenseDisableStatus(true);
      $('input[name="licenseExpDate"]').prop("disabled", true);
    }
  };

  useEffect(() => {
    getUserProfileDetil();
    $("input, select").prop("disabled", true);
    setDobDisableStatus(true);
    setLicenseDisableStatus(true);
    setInsuranceDisableStatus(true);
  }, []);

  const [clinicList, setClinicList] = useState([]);

  const specializationListtt = async () => {
    let dataList = await getSetPriceforEntitiesAction();
    // console.log("dataListdataList", dataList.data);

    setClinicList(dataList.data);
  };
  useEffect(() => {
    specializationListtt();
  }, []);

  return (
    <div className="row">
      <div className="col-md-3 left_dashboard scrollbar2 leftContainer">
        <SideMenu />
      </div>
      <div className="col-md-9 right_dashboard scrollbar3 rightContainer">
        <div className="content-setion">
          <div className="section_title m-25">
            <h3>{cptId && cptId !== "" ? "Provider Profile" : "My Profile"}</h3>
          </div>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="row register_patient_row2">
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-6">
                    <label>First Name*</label>
                    <div className="form-group">
                      <input
                        onChange={(e) => inputHandler(e, "firstName")}
                        name="firstName"
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        defaultValue={
                          signupForm.firstName ? signupForm.firstName : ""
                        }
                        ref={register({
                          required: "Required",
                        })}
                      />
                      <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "firstName")}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Last Name*</label>
                    <div className="form-group">
                      <input
                        onChange={(e) => inputHandler(e, "lastName")}
                        name="lastName"
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        defaultValue={
                          signupForm.lastName ? signupForm.lastName : ""
                        }
                        ref={register({
                          required: "Required",
                        })}
                      />
                      <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "lastName")}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>
                      Input rate in USD  to charge clients for 30 minute sessions*
                    </label>
                    <div className="form-group">
                      <input
                        onChange={(e) => inputHandler(e, "price_for_30_min")}
                        name="price_for_30_min"
                        type="text"
                        className="form-control"
                        placeholder="Input rate to charge clients for 30 minute sessions"
                        defaultValue={
                          signupForm.price_for_30_min
                            ? signupForm.price_for_30_min
                            : ""
                        }
                        ref={register({
                          required: "Required",
                        })}
                      />
                      Recover will collect{clinicList.seacondDPamount === "dollarAmount" ? "$" : ""} {clinicList.visitShare}  {clinicList.seacondDPamount === "percentageAmount" ? "%" : ""} of the rate
                      you set for 30 minute sessions
                      <div className="text-danger">
                        {ShowFormErrors(
                          errors,
                          signupErrors,
                          "price_for_30_min"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>
                      Input rate in USD  to charge clients for 60 minute sessions*
                    </label>
                    <div className="form-group">
                      <input
                        onChange={(e) => inputHandler(e, "price_for_60_min")}
                        name="price_for_60_min"
                        type="text"
                        className="form-control"
                        placeholder="Input rate to charge clients for 60 minute sessions"
                        defaultValue={
                          signupForm.price_for_60_min
                            ? signupForm.price_for_60_min
                            : ""
                        }
                        ref={register({
                          required: "Required",
                        })}
                      />
                      Recover will collect {clinicList.seacondDPamount === "dollarAmount" ? "$" : ""} {clinicList.visitShare}  {clinicList.seacondDPamount === "percentageAmount" ? "%" : ""} of the rate
                      you set for 60 minute sessions
                      <div className="text-danger">
                        {ShowFormErrors(
                          errors,
                          signupErrors,
                          "price_for_60_min"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Bio</label>
                    <div className="form-group">
                      <input
                        onChange={(e) => inputHandler(e, "bio")}
                        name="bio"
                        type="text"
                        className="form-control"
                        placeholder="Bio"
                        defaultValue={signupForm.bio ? signupForm.bio : ""}
                        // ref={register({
                        //   required: "Required",
                        // })}
                      />
                      {/* <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "bio")}
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Credentials*</label>
                    <div className="form-group">
                      <input
                        onChange={(e) => inputHandler(e, "education")}
                        name="education"
                        type="text"
                        className="form-control"
                        placeholder="Credentials"
                        defaultValue={
                          signupForm.education ? signupForm.education : ""
                        }
                        ref={register({
                          required: "Required",
                        })}
                      />
                      <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "education")}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Date of Birth*</label>
                    <div className="form-group">
                      <DatePicker
                        name="dob"
                        selected={dobValue}
                        maxDate={new Date()}
                        className="form-control"
                        dateFormat={appConstants.DateFormatNew}
                        placeholderText="Click to select a Date of Birth"
                        onChange={(date) => {
                          setDobValue(date);
                          setValue("dob", date);
                        }}
                        ref={register(
                          { name: "dob" },
                          { required: dobValue ? "" : "Required" }
                        )}
                        showMonthDropdown
                        useShortMonthInDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={5}
                        disabled={dobDisableStatus}
                      />
                      {!dobValue && (
                        <div className="text-danger">
                          {ShowFormErrors(errors, signupErrors, "dob")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Gender*</label>
                    <div className="radio-bb">
                      <FormGroup check>
                        <Label check htmlFor="forMale">
                          <input
                            type="radio"
                            className="clas-radio"
                            id="forMale"
                            name="gender"
                            onChange={(e) => radioHandler(e, "male")}
                            checked={
                              signupForm.gender === "male" ? "checked" : ""
                            }
                          />{" "}
                          Male{" "}
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check htmlFor="forFemale">
                          <Input
                            type="radio"
                            className="clas-radio"
                            id="forFemale"
                            name="gender"
                            onChange={(e) => radioHandler(e, "female")}
                            checked={
                              signupForm.gender === "female" ? "checked" : ""
                            }
                          />{" "}
                          Female{" "}
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check htmlFor="forOther">
                          <Input
                            type="radio"
                            className="clas-radio"
                            id="forOther"
                            name="gender"
                            onChange={(e) => radioHandler(e, "other")}
                            checked={
                              signupForm.gender === "other" ? "checked" : ""
                            }
                          />{" "}
                          Other{" "}
                        </Label>
                      </FormGroup>
                      <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "gender")}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Email*</label>
                    <div className="form-group">
                      <input
                        onChange={(e) => inputHandler(e, "email")}
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        defaultValue={signupForm.email ? signupForm.email : ""}
                        ref={register({
                          required: "Required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Please enter a valid email address.",
                          },
                        })}
                      />
                      <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "email")}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Phone Number*</label>
                    <div className="form-group phone_field">
                      <select className="form-control" name="phoneCode">
                        <option defaultValue="+1">+1</option>
                      </select>
                      <NumberFormat
                        format="###-###-####"
                        name="phone"
                        //pattern="[0-9,-]{12}"
                        placeholder="Phone Number Here"
                        className="form-control"
                        value={signupForm.phone ? signupForm.phone : ""}
                        onChange={(e) => {
                          inputHandler(e, "phone");
                          setValue("phone", e.target.value);
                        }}
                        ref={register(
                          signupForm.phone === "" ? { name: "phone" } : "",
                          {
                            required: signupForm.phone === "" ? "Required" : "",
                            pattern: {
                              value: /^(?=.*\d)[\d-]{12}$/,
                              message:
                                "Phone number should be 10 digit number.",
                            },
                          }
                        )}
                      />
                      {signupForm.phone === "" && (
                        <div className="text-danger">
                          {ShowFormErrors(errors, signupErrors, "phone")}
                        </div>
                      )}
                      {signupForm.phone &&
                        signupForm.phone.replace(/[-]/g, "").trim().length !==
                          10 && (
                          <div className="text-danger">
                            {ShowFormErrors(errors, signupErrors, "phone")}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <label>Social Security Number*</label>
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={(e) =>
                          inputHandler(e, "socialSecurityNumber")
                        }
                        name="socialSecurityNumber"
                        className="form-control"
                        placeholder="Social Security Number"
                        defaultValue={
                          signupForm.socialSecurityNumber
                            ? signupForm.socialSecurityNumber
                            : ""
                        }
                        ref={register({
                          required: "Required",
                        })}
                      />
                      <div className="text-danger">
                        {ShowFormErrors(
                          errors,
                          signupErrors,
                          "socialSecurityNumber"
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Specialization Added */}

                  <div className="col-lg-6">
                                <label>Specialization* </label>
                    
                                <div className="form-group">
                                  <Multiselect
                                      name="specialization"
                                      value={
                                        signupForm.specialization ? signupForm.specialization : ""
                                      }
                                      onSelect={e => inputSelectHandler(e, 'specialization')} 
                                        ref={register({ required: 'Required' })}
                                      isObject={true}
                                      options={imageData}
                                      showCheckbox
                                      disable={!EditStatus}
                                      selectedValues={specializationValue}
                                      onRemove={(a,b)=>{if(a?.length<1){setIsSelected(false);}}}
                                      displayValue="specializationName"
                                  />
                                      {!isSelected &&
                                          (<div className="text-danger">
                                            {ShowFormErrors(errors,signupErrors,"specialization",isSelected)}
                                            </div>)
                                        }
                                </div>


                              
                  </div>



                  <GeoAddressComponent
                    history={history}
                    register={register}
                    errors={errors}
                    formData={signupForm}
                    inputHandler={inputHandler}
                    signupErrors={signupErrors}
                    watch={watch}
                  />

                  {!signupForm.clinicId && (
                    <div className="row in_pt">
                      <div className="col-lg-12 docheading">
                        <h4>Articles of Incorporation (or SSN)</h4>
                      </div>
                      <div className="col-lg-12">
                        <label>
                          Upload Articles of Incorporation Doc or Copy of SSN
                          Card
                        </label>
                        <div className="form-group">
                          {EditStatus && (
                            <input
                              type="file"
                              name="npiDoc"
                              accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc"
                              onChange={(e) => fileChangedHandler(e, "npiDoc")}
                              ref={register({
                                required:
                                  signupForm.npiDoc === "" ? "Required" : "",
                              })}
                            />
                          )}
                          {EditStatus &&
                          signupForm.npiDocPreview &&
                          signupForm.npiDocPreview !== "" ? (
                            <div className="fileDocs">
                              <img src={signupForm.npiDocPreview} alt="img" />
                            </div>
                          ) : (
                            <div className="fileDocs">
                              {signupForm.npiDoc
                                ? renderHTML(
                                    checkExtFunction(signupForm.npiDoc)
                                  )
                                : ""}
                            </div>
                          )}
                          <div className="text-danger">
                            {ShowFormErrors(errors, signupErrors, "npiDoc")}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 docheading">
                        <h4>W9 with TIN (or SSN)</h4>
                      </div>
                      <div className="col-lg-8">
                        <label>Tax Identification Number (or SSN)</label>
                        <div className="form-group">
                          <input
                            type="text"
                            onChange={(e) => inputHandler(e, "w9Form")}
                            name="w9Form"
                            className="form-control"
                            placeholder="Employer Identification Number"
                            defaultValue={
                              signupForm.w9Form ? signupForm.w9Form : ""
                            }
                            ref={register({
                              // required: "Required",
                            })}
                          />
                          <div className="text-danger">
                            {ShowFormErrors(errors, signupErrors, "w9Form")}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <label>Upload W9 Doc*</label>
                        <div className="form-group">
                          {EditStatus && (
                            <input
                              type="file"
                              name="w9FormDoc"
                              accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc"
                              onChange={(e) =>
                                fileChangedHandler(e, "w9FormDoc")
                              }
                              ref={register({
                                required:
                                  signupForm.w9FormDoc === "" ? "Required" : "",
                              })}
                            />
                          )}
                          {EditStatus &&
                          signupForm.w9FormDocPreview &&
                          signupForm.w9FormDocPreview !== "" ? (
                            <div className="fileDocs">
                              <img
                                src={signupForm.w9FormDocPreview}
                                alt="img"
                              />
                            </div>
                          ) : (
                            <div className="fileDocs">
                              {signupForm.w9FormDoc
                                ? renderHTML(
                                    checkExtFunction(signupForm.w9FormDoc)
                                  )
                                : ""}
                            </div>
                          )}
                          <div className="text-danger">
                            {ShowFormErrors(errors, signupErrors, "w9FormDoc")}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-lg-12 docheading">
                    {!signupForm.clinicId ? (
                      <h4>Liability Insurance</h4>
                    ) : (
                      <h4>Liability Insurance (Clinic or Individual)</h4>
                    )}
                  </div>
                  <div className="col-lg-4">
                    <label>Policy Number</label>
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={(e) => inputHandler(e, "insuranceNumber")}
                        name="insuranceNumber"
                        className="form-control"
                        placeholder="Policy Number"
                        defaultValue={
                          signupForm.insuranceNumber
                            ? signupForm.insuranceNumber
                            : ""
                        }
                        ref={register({
                          // required: "Required",
                        })}
                      />
                      <div className="text-danger">
                        {ShowFormErrors(
                          errors,
                          signupErrors,
                          "insuranceNumber"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label>Expiration Date*</label>
                    <div className="form-group">
                      <DatePicker
                        minDate={new Date()}
                        name="insuranceExpDate"
                        className="form-control"
                        selected={insuranceDate}
                        dateFormat={appConstants.DateFormatNew}
                        placeholderText="Click to select Expiration Date"
                        onChange={(date) => {
                          setInsuranceDate(date);
                          setValue("insuranceExpDate", date);
                          setInsuranceDateValidation(true);
                        }}
                        ref={register(
                          { name: "insuranceExpDate" },
                          { required: insuranceDate ? "" : "Required" }
                        )}
                        showMonthDropdown
                        useShortMonthInDropdown
                        showYearDropdown
                        disabled={insuranceDisableStatus}
                      />
                      {!insuranceDate && (
                        <div className="text-danger">
                          {ShowFormErrors(
                            errors,
                            signupErrors,
                            "insuranceExpDate"
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label>Upload Insurance Doc</label>
                    <div className="form-group">
                      {EditStatus && (
                        <input
                          type="file"
                          name="insuranceDoc"
                          accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc"
                          className="form-control"
                          disabled={insuranceDisableStatus}
                          onChange={(e) =>
                            fileChangedHandler(e, "insuranceDoc")
                          }
                          ref={register({
                            required: insuranceDateValidation ? "Required" : "",
                          })}
                        />
                      )}

                      {EditStatus &&
                      signupForm.insuranceDocPreview &&
                      signupForm.insuranceDocPreview !== "" ? (
                        <div className="fileDocs">
                          <img src={signupForm.insuranceDocPreview} alt="img" />
                        </div>
                      ) : (
                        <div className="fileDocs">
                          {signupForm.insuranceDoc
                            ? renderHTML(
                                checkExtFunction(signupForm.insuranceDoc)
                              )
                            : ""}
                        </div>
                      )}
                      <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "insuranceDoc")}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 docheading">
                    <h4>Professional License</h4>
                  </div>
                  <div className="col-lg-4">
                    <label>License Number*</label>
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={(e) => inputHandler(e, "licenseNumber")}
                        name="licenseNumber"
                        className="form-control"
                        placeholder="License Number"
                        defaultValue={
                          signupForm.licenseNumber
                            ? signupForm.licenseNumber
                            : ""
                        }
                        ref={register({
                          required: "Required",
                        })}
                      />
                      <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "licenseNumber")}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label>Expiration Date*</label>
                    <div className="form-group">
                      <DatePicker
                        minDate={new Date()}
                        name="licenseExpDate"
                        selected={licenseDate}
                        className="form-control"
                        dateFormat={appConstants.DateFormatNew}
                        placeholderText="Click to select Expiration Date"
                        onChange={(date) => {
                          setLicenseDate(date);
                          setValue("licenseExpDate", date);
                          setLicenseDateValidation(true);
                        }}
                        ref={register(
                          { name: "licenseExpDate" },
                          { required: licenseDate ? "" : "Required" }
                        )}
                        showMonthDropdown
                        useShortMonthInDropdown
                        showYearDropdown
                        disabled={licenseDisableStatus}
                      />
                      {!licenseDate && (
                        <div className="text-danger">
                          {ShowFormErrors(
                            errors,
                            signupErrors,
                            "licenseExpDate"
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label>Upload License Doc*</label>
                    <div className="form-group">
                      {EditStatus && (
                        <input
                          type="file"
                          name="licenseDoc"
                          accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc"
                          className="form-control"
                          disabled={licenseDisableStatus}
                          onChange={(e) => fileChangedHandler(e, "licenseDoc")}
                          ref={register({
                            required: licenseDateValidation ? "Required" : "",
                          })}
                        />
                      )}

                      {EditStatus &&
                      signupForm.licenseDocPreview &&
                      signupForm.licenseDocPreview !== "" ? (
                        <div className="fileDocs">
                          <img src={signupForm.licenseDocPreview} alt="img" />
                        </div>
                      ) : (
                        <div className="fileDocs">
                          {signupForm.licenseDoc
                            ? renderHTML(
                                checkExtFunction(signupForm.licenseDoc)
                              )
                            : ""}
                        </div>
                      )}
                      <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "licenseDoc")}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 t-pp">
                    <Link
                      to="#"
                      className="btn btn-primary rt"
                      onClick={(e) => onEditClick(e, EditStatus)}
                    >
                      {EditStatus ? "Cancel" : "Edit"}
                    </Link>
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value="Update"
                      onClick={()=>{if(signupForm.specialization?.length<1) {
                        setIsSelected(false)
                      }}}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 text-center">
                <div className="bg-cirpatient">
                  <div className="prof_section">
                    <label>
                      {signupForm.profileImgPreview &&
                        signupForm.profileImgPreview !== "" && (
                          <img src={signupForm.profileImgPreview} alt="img" />
                        )}
                      {signupForm.profileImg &&
                        signupForm.profileImg !== "" &&
                        !signupForm.profileImgPreview && (
                          <img
                            src={`${
                              process.env.REACT_APP_STATIC_S3_URL +
                              signupForm.profileImg
                            }`}
                            alt="img"
                          />
                        )}
                      {!signupForm.profileImg &&
                        !signupForm.profileImgPreview && (
                          <img src="/static/img/icon-profiles.png" alt="img" />
                        )}
                      <input
                        type="file"
                        name="profileImg"
                        accept=".bmp,.jpg,.png,.jpeg"
                        onChange={(e) => fileChangedHandler(e, "profileImg")}
                        style={{ display: "none" }}
                        ref={register({
                          required:
                            signupForm.profileImg === "" ? "Required" : "",
                        })}
                      />
                    </label>
                    <br />
                    <br />
                  </div>
                  <div className="text-danger">
                    {ShowFormErrors(errors, signupErrors, "profileImg")}
                  </div>

                  <div className="userid">
                    <p>
                      Provider Code:{" "}
                      {signupForm.clinicId && signupForm.clinicId !== ""
                        ? signupForm.clinicId.replace("C", "CPT")
                        : signupForm.clinicNumberName}
                    </p>
                    <button
                      type="button"
                      className="nbtn"
                      onClick={(e) => setIsChangePassword(true)}
                    >
                      <span>Change Password</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <Dialog
            className="form_designpage"
            onClose={(e) => setIsChangePassword(false)}
            title=""
            canOutsideClickClose={false}
            isOpen={isChangePassword}
          >
            <ChangePassword
              history={history}
              setIsChangePassword={setIsChangePassword}
              cptId={cptId}
            />
          </Dialog>
          <div
            className="spinner"
            style={{ display: spinner ? "block" : "none" }}
          >
            <img src="/static/img/spinner.gif" alt="loader" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(ProfilePage);
