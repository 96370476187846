import React, { useEffect } from "react";
import { withLocalize } from "react-localize-redux";
import MetaTags from "react-meta-tags";
import BreadcrumbsComp from "../../../_Common/Breadcrumbs";

const HowRecoverWorksPage = ({ history }) => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div>
      <MetaTags>
        <title>How Recover Works</title>
        <meta
          name="description"
          content="To access e-INSCRIPTION’s services through a website or mobile app, you need an account on our platform. To create an account you have to provide accurate info"
        />
        <meta
          name="keywords"
          content="LIVE online video streaming providers Indore, online video streaming providers, Online teaching in Indore, Online Training in Indore, Virtual classes in Indore "
        />
      </MetaTags>
      <section className="how_work_page pad-reggis inner_pagewrap">
        <BreadcrumbsComp
          bcChanges={[
            { path: "/how-recover-works", breadcrumb: "How Recover Works" },
          ]}
        />

        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-left">
              <div className="how_work_wrap white_box">
                <div className="section_title page_ttl text-center">
                  <h3>How Recover Works</h3>
                  <div className="decor2"></div>
                </div>
                <h4>How Recover Works, Clients:</h4>
                <ol>
                  <li>
                    Recover allows you to receive healthcare virtually in the
                    location of your choice. The calendar tool allows you to set
                    an availability most convenient for you. Recover works to
                    match you with a specialist during the times you have
                    allotted. You will be able to upload any medical documents
                    that you feel will help the provider better assess and treat
                    you. Additionally, you will have easy access to your
                    treatment notes after each visit.
                  </li>
                  <li>
                    Pricing: The price per session will vary depending on the
                    provider’s rates for 60 minute or 30 minute sessions. You
                    will be able to view the price per provider when you select
                    on the provider profile.{" "}
                  </li>
                </ol>
                <h4>How Recover Works, Independent Providers:</h4>
                <ol>
                  <li>
                    Recover is hiring licensed qualified specialists to treat
                    clients through our telehealth platform. If you are an
                    independent contractor with a current California healthcare
                    license/certification and liability insurance, Recover will
                    pay you per visit to treat clients. You can use the calendar
                    interface to mark your availability and Recover will provide
                    clients that match your openings. You can build your own
                    practice using Recover!
                  </li>
                  <li>
                    Requirements: California license/certification, W9,
                    Liability Insurance, computer with a camera/sound, as well
                    as a professional area in your house to provide telehealth
                    treatments. Providers who register as a life coach will
                    provider a copy of their certification and certification
                    date instead of a license and license number.
                  </li>
                  <li>
                    Pricing: Recover allows providers to set their own rates.
                    During the registration process, providers will view the
                    rate Recover subtracts from their total amount.
                  </li>
                </ol>
                <h4>How Recover Works, Clinics:</h4>
                <ol>
                  <li>
                    Recover offers a solution for clinics when they receive last
                    minute cancelations from clients. Recover allows clinics to
                    mark available openings using the calendar interface and
                    will pair your provider with a client who is available
                    during the same time. Recover aims to decrease loss of
                    revenue for clinics who have last minute cancelations.
                  </li>
                  <li>
                    Each clinic registered with Recover will be given a ‘clinic
                    code’ to input when using the platform. Clinic
                    administrators have access to their providers schedules and
                    can mark the providers availability.
                  </li>
                  <li>
                    Requirements: Articles of Incorporation, W9, Liability
                    Insurance, and a computer with camera/sound for providers to
                    treat clients in a professional area.
                  </li>
                  <li>
                    Pricing: Clinic administrators will determine how much they
                    will pay their providers per 60 minute and 30 minute
                    session. Recover allows clinics to set their own rates.
                    During the registration process, clinic administrators will
                    view the rate Recover subtracts from their total amount.
                  </li>
                </ol>
                <h4>How Recover Works, Clinic Providers:</h4>
                <ol>
                  <li>
                    Recover will help fill your cancelations during work hours
                    and allow you to treat clients via telehealth. Your clinic
                    administrator will provide a ‘clinic code’ for you to use to
                    access your Recover account. You or your clinic
                    administrator can mark your availability and Recover will
                    pair you with a client who has an opening during your
                    available time.
                  </li>
                  <li>
                    If your clinic approves you to treat clients independently
                    after clinic hours (or on the weekends), you have the option
                    of doing so with Recover. Providers that treat clients at a
                    clinic are independently required to provide a copy of their
                    California license/certification, W9, and proof of liability
                    insurance.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default withLocalize(HowRecoverWorksPage);
