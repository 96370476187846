import React,{useState} from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import './SignUp.css';

const SignUp = ({history,setIsSignupForm}) => {
	const [signupForm,setSignupForm] = useState({});
	const selectHandler =(item,field)=>{
		let signupFormData = signupForm;
		signupFormData[field] = item.target.value;
		setSignupForm(signupFormData);
	}

	const onRegister = async (e) =>{
		e.preventDefault();
		if(signupForm.registerType && signupForm.registerType!==''){
			setIsSignupForm(false);
			history.push({
				pathname: '/register',
				state: { registerType: signupForm.registerType }
			});
		}
	}

	return (
		 <div className="form_modals registt-form">
			<h5 className="modal-title">Registration</h5>
			 <div className="form-group">
				<select className="form-control" value={signupForm.registerType} onChange={e=>selectHandler(e,"registerType")}>
					<option value=""> Select </option>
					<option value="Patient">CLIENT REGISTRATION</option>
					<option value="PT">INDEPENDENT PROVIDER REGISTRATION</option>
					<option value="Clinic">REGISTER CLINIC WITH RECOVER </option>

				</select>
			 </div>
			  <div className="row">
				<div className="col-md-12">
					<center><button className="btn btn-primary" onClick={e=>onRegister(e)}>Continue
					</button></center>
				</div>
			</div>
		</div>
	);
}
export default withLocalize(withRouter(SignUp));