import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { FormError, ShowFormErrors } from "../../../../_Common/formError";
import toastr from "../../../../_Common/toaster";
import {
  getUserProfileAction,
  editProfileAction,
} from "../../../Actions/User.action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  appConstants,
  checkExtFunction,
  setSessionValue,
  toUTC,
  getLocalDate,
} from "../../../../_Constants/app.constants";
import { timezones } from "../../../../_Constants/timezones";
import GeoAddressComponent from "../../../../_Common/GeoAddressManage";
import moment from "moment";
import $ from "jquery";
import { Dialog } from "@blueprintjs/core";
import ChangePassword from "../ChangePassword/index";
import renderHTML from "react-render-html";
import NumberFormat from "react-number-format";
import SideMenu from "../SideMenu";

const ProfilePage = ({ history, setIsSignInForm, setIslogedIn }) => {
  const dispatch = useDispatch();
  const [signupForm, setSignupForm] = useState({});
  const [signupErrors, setSignupErrors] = useState({});
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [dobValue, setDobValue] = useState();
  const { register, handleSubmit, setValue, errors, setError } = useForm();
  const [insuranceDate, setInsuranceDate] = useState();
  const [EditStatus, setEditStatus] = useState(false);
  const [insuranceDisableStatus, setInsuranceDisableStatus] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [phoneValueNew, setPhoneValue] = useState(false);
  const [insuranceDateValidation, setInsuranceDateValidation] = useState(false);

  const getUserProfileDetil = async (status) => {
    let userDetail = await getUserProfileAction();
    if (status === "UPDATE_SESSION" && userDetail && userDetail.data) {
      setSessionValue(userDetail.data);
      window.location.reload();
    }
    userDetail.data.time_zone = userDetail.data.timeZone.text;
    userDetail.data.timeZone =
      userDetail.data.timeZone && userDetail.data.timeZone
        ? JSON.stringify(userDetail.data.timeZone)
        : "";
    setSignupForm(userDetail.data);

    if (userDetail && userDetail.data !== "") {
      let insuranceDateValue = userDetail.data.insuranceExpDate
        ? new Date(userDetail.data.insuranceExpDate)
        : "";
      setInsuranceDate(insuranceDateValue);
    }
  };

  const inputHandler = (e, field) => {
    let signupFormData = signupForm;
    if (
      field === "phone" &&
      e.target.value.replace(/[-]/g, "").trim().length === 10
    ) {
      $('input[name="phone"]').removeClass("requiredClass");
    }
    if (field == "time_zone") {
      let timezoneValue = timezones.filter(function (timezones) {
        return timezones.text == e.target.value;
      });
      signupFormData["timeZone"] = JSON.stringify(timezoneValue[0]);
    }
    signupFormData[field] = e.target.value;
    setSignupForm({ ...signupForm, ...signupFormData });
  };

  const fileChangedHandler = (event, elename) => {
    event.preventDefault();
    if (!event.target.files[0] && event.target.files[0] === undefined) {
      return false;
    }
    let signupFormData = signupForm;
    let file = event.target.files[0];
    var fileName = file && file.name ? file.name : "";
    let extensions = fileName
      .substring(fileName.lastIndexOf(".") + 1)
      .toLowerCase();
    if (
      extensions === "bmp" ||
      extensions === "jpg" ||
      extensions === "png" ||
      extensions === "jpeg"
    ) {
      signupFormData[elename + "Preview"] = URL.createObjectURL(
        event.target.files[0]
      );
      signupFormData[elename] = event.target.files[0];
      setSignupForm({ ...signupForm, ...signupFormData });
    } else if (extensions === "docx" || extensions === "doc") {
      signupFormData[elename + "Preview"] = "/static/img/docIcon.png";
      signupFormData[elename] = event.target.files[0];
      setSignupForm({ ...signupForm, ...signupFormData });
    } else if (extensions === "pdf") {
      signupFormData[elename + "Preview"] = "/static/img/pdfIcon.png";
      signupFormData[elename] = event.target.files[0];
      setSignupForm({ ...signupForm, ...signupFormData });
    } else {
      let err = [{ msg: "Extensions doesn't match", param: elename }];
      setSignupErrors(FormError(err));
    }
  };

  const onFormSubmit = async () => {
    setSpinner(true);
    signupForm["phone"] = signupForm["phone"].replace(/\D/g, "");
    signupForm["insuranceExpDate"] = insuranceDate ? toUTC(insuranceDate) : "";
    signupForm["timeZone"] = signupForm["timeZone"];
    let res = {};
    let formErrors = {};
    res = await editProfileAction(signupForm);
    if (res.err && res.err.length > 0) {
      formErrors = FormError(res.err);
    }
    setSignupErrors(formErrors);
    if (res.success) {
      getUserProfileDetil("UPDATE_SESSION");
      setEditStatus(false);
      setSpinner(false);
      toastr.show({ message: res.msg, intent: "success" });
      $("input, select").prop("disabled", true);
    } else if (res.success === false) {
      setSpinner(false);
      toastr.show({ message: res.msg, intent: "danger" });
    }
  };

  const onEditClick = (e, status) => {
    e.preventDefault();
    setEditStatus(!status);
    getUserProfileDetil();
    setInsuranceDisableStatus(false);
    $("input, select").prop("disabled", status);
    $("input[name=clinicName]").prop("disabled", true);
    $("input[name=email]").prop("disabled", true);
    $("input[name=address]").prop("disabled", true);
    $("input[name=city]").prop("disabled", true);
    $("input[name=state]").prop("disabled", true);
    $("input[name=zipCode]").prop("disabled", true);

    var currentDateStr = moment().format("x");
    var insuranceDateMinus = moment(insuranceDate).add(-1, "M").format("x");
    if (insuranceDateMinus > currentDateStr) {
      setInsuranceDisableStatus(true);
      $('input[name="insuranceExpDate"]').prop("disabled", true);
    }
  };

  useEffect(() => {
    getUserProfileDetil();
    $("input, select").prop("disabled", true);
    setInsuranceDisableStatus(true);
  }, []);

  return (
    <div className="row">
      <div className="col-md-3 left_dashboard scrollbar2 leftContainer">
        <SideMenu />
      </div>
      <div className="col-md-9 right_dashboard scrollbar3 rightContainer">
        <div className="content-setion">
          <div className="section_title m-25">
            <h3>My Profile</h3>
          </div>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="row register_patient_row2" id="someId">
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-6">
                    <label>Clinic Name *</label>
                    <div className="form-group">
                      <input
                        name="clinicName"
                        type="text"
                        className="form-control"
                        placeholder="clinicName"
                        defaultValue={
                          signupForm.fullName ? signupForm.fullName : ""
                        }
                      />
                      <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "clinicName")}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Clinic Contact*</label>
                    <div className="form-group">
                      <input
                        name="clinicContact"
                        type="text"
                        onChange={(e) => inputHandler(e, "clinicContact")}
                        className="form-control"
                        placeholder="Name"
                        defaultValue={
                          signupForm.clinicContact
                            ? signupForm.clinicContact
                            : ""
                        }
                        ref={register({
                          required: "Required",
                        })}
                      />
                      <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "clinicContact")}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Email*</label>
                    <div className="form-group">
                      <input
                        onChange={(e) => inputHandler(e, "email")}
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        defaultValue={signupForm.email ? signupForm.email : ""}
                        ref={register({
                          required: "Required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Please enter a valid email address.",
                          },
                        })}
                      />
                      <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "email")}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Phone Number*</label>
                    <div className="form-group phone_field">
                      <select className="form-control" name="phoneCode">
                        <option defaultValue="+1">+1</option>
                      </select>
                      <NumberFormat
                        format="###-###-####"
                        name="phone"
                        //pattern="[0-9,-]{12}"
                        placeholder="Phone Number Here"
                        className="form-control"
                        value={signupForm.phone ? signupForm.phone : ""}
                        onChange={(e) => {
                          inputHandler(e, "phone");
                          setValue("phone", e.target.value);
                        }}
                        ref={register(
                          signupForm.phone === "" ? { name: "phone" } : "",
                          {
                            required: signupForm.phone === "" ? "Required" : "",
                            pattern: {
                              value: /^(?=.*\d)[\d-]{12}$/,
                              message:
                                "Phone number should be 10 digit number.",
                            },
                          }
                        )}
                      />
                      {signupForm.phone === "" && (
                        <div className="text-danger">
                          {ShowFormErrors(errors, signupErrors, "phone")}
                        </div>
                      )}
                      {signupForm.phone &&
                        signupForm.phone.replace(/[-]/g, "").trim().length !==
                          10 && (
                          <div className="text-danger">
                            {ShowFormErrors(errors, signupErrors, "phone")}
                          </div>
                        )}
                      {/*<input name="phone" onChange={e=>inputHandler(e,'phone')} type="text" maxLength="12" 
                        className="form-control" placeholder='Phone'
                        //defaultValue={phoneValueNew} 
                        value={signupForm && signupForm.phone?signupForm.phone.replace(/(\d{3})(\d{3})(\d+)/, '$1-$2-$3'):''}
                        ref={register({
                          required: 'Required',
                          pattern: {
                            value:  /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/,
                            message: "Phone number should be 10 digit number."
                          }
                        })}
                      />
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"phone")}</div>*/}
                    </div>
                  </div>

                  <GeoAddressComponent
                    history={history}
                    register={register}
                    errors={errors}
                    formData={signupForm}
                    inputHandler={inputHandler}
                    signupErrors={signupErrors}
                  />

                  <div className="col-lg-12 docheading">
                    <h4>W9 with TIN</h4>
                  </div>
                  <div className="col-lg-8">
                    <label>Tax Identification Number*</label>
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={(e) => inputHandler(e, "w9WithEIN")}
                        name="w9WithEIN"
                        className="form-control"
                        placeholder="Tax Identification Number"
                        defaultValue={
                          signupForm.w9WithEIN ? signupForm.w9WithEIN : ""
                        }
                        ref={register({
                          required: "Required",
                        })}
                      />
                      <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "w9WithEIN")}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label>Upload W9 Doc.*</label>
                    <div className="form-group">
                      {EditStatus && (
                        <input
                          type="file"
                          name="w9WithEINDoc"
                          accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc"
                          onChange={(e) =>
                            fileChangedHandler(e, "w9WithEINDoc")
                          }
                          ref={register({
                            required:
                              signupForm.w9WithEINDoc === "" ? "Required" : "",
                          })}
                        />
                      )}
                      {EditStatus &&
                      signupForm.w9WithEINDocPreview &&
                      signupForm.w9WithEINDocPreview !== "" ? (
                        <div className="fileDocs">
                          <img src={signupForm.w9WithEINDocPreview} alt="img" />
                        </div>
                      ) : (
                        <div className="fileDocs">
                          {signupForm.w9WithEINDoc
                            ? renderHTML(
                                checkExtFunction(signupForm.w9WithEINDoc)
                              )
                            : ""}
                        </div>
                      )}
                      <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "w9WithEINDoc")}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 docheading">
                    <h4>Articles of Incorporation</h4>
                    <div className="row">
                      <div className="col-lg-12">
                        <label>Upload Articles of Incorporation Doc*</label>
                        <div className="form-group">
                          {EditStatus && (
                            <input
                              type="file"
                              name="npiDoc"
                              accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc"
                              onChange={(e) => fileChangedHandler(e, "npiDoc")}
                              ref={register({
                                required:
                                  signupForm.npiDoc === "" ? "Required" : "",
                              })}
                            />
                          )}
                          <div className="text-danger">
                            {ShowFormErrors(errors, signupErrors, "npiDoc")}
                          </div>
                        </div>

                        {EditStatus &&
                        signupForm.npiDocPreview &&
                        signupForm.npiDocPreview !== "" ? (
                          <div className="fileDocs">
                            <img src={signupForm.npiDocPreview} alt="img" />
                          </div>
                        ) : (
                          <div className="fileDocs">
                            {signupForm.npiDoc
                              ? renderHTML(checkExtFunction(signupForm.npiDoc))
                              : ""}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 docheading">
                    <h4>Proof of Practice Malpractice Insurance</h4>
                  </div>
                  <div className="col-lg-4">
                    <label> Policy Number*</label>
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={(e) => inputHandler(e, "insuranceNumber")}
                        name="insuranceNumber"
                        className="form-control"
                        placeholder=" Policy Number"
                        defaultValue={
                          signupForm.insuranceNumber
                            ? signupForm.insuranceNumber
                            : ""
                        }
                        ref={register({
                          required: "Required",
                        })}
                      />
                      <div className="text-danger">
                        {ShowFormErrors(
                          errors,
                          signupErrors,
                          "insuranceNumber"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label>Expiration Date*</label>
                    <div className="form-group">
                      <DatePicker
                        minDate={new Date()}
                        name="insuranceExpDate"
                        className="form-control"
                        selected={insuranceDate}
                        dateFormat={appConstants.DateFormatNew}
                        placeholderText="Click to select Expiration Date"
                        onChange={(date) => {
                          setInsuranceDate(date);
                          setValue("insuranceExpDate", date);
                          setInsuranceDateValidation(true);
                        }}
                        ref={register(
                          { name: "insuranceExpDate" },
                          { required: insuranceDate ? "" : "Required" }
                        )}
                        showMonthDropdown
                        useShortMonthInDropdown
                        showYearDropdown
                        disabled={insuranceDisableStatus}
                      />
                      {!insuranceDate && (
                        <div className="text-danger">
                          {ShowFormErrors(
                            errors,
                            signupErrors,
                            "insuranceExpDate"
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label>Upload Insurance Doc.*</label>
                    <div className="form-group">
                      {EditStatus && (
                        <input
                          type="file"
                          name="insuranceDoc"
                          accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc"
                          className="form-control"
                          disabled={insuranceDisableStatus}
                          onChange={(e) =>
                            fileChangedHandler(e, "insuranceDoc")
                          }
                          ref={register({
                            required: insuranceDateValidation ? "Required" : "",
                          })}
                        />
                      )}
                      {EditStatus &&
                      signupForm.insuranceDocPreview &&
                      signupForm.insuranceDocPreview !== "" ? (
                        <div className="fileDocs">
                          <img src={signupForm.insuranceDocPreview} alt="img" />
                        </div>
                      ) : (
                        <div className="fileDocs">
                          {signupForm.insuranceDoc
                            ? renderHTML(
                                checkExtFunction(signupForm.insuranceDoc)
                              )
                            : ""}
                        </div>
                      )}
                      <div className="text-danger">
                        {ShowFormErrors(errors, signupErrors, "insuranceDoc")}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 t-pp">
                    <Link
                      to="#"
                      className="btn btn-primary rt"
                      onClick={(e) => onEditClick(e, EditStatus)}
                    >
                      {EditStatus ? "Cancel" : "Edit"}
                    </Link>
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value="Update"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 text-center">
                <div className="bg-cirpatient">
                  <div className="prof_section">
                    <label>
                      {signupForm.profileImgPreview &&
                        signupForm.profileImgPreview !== "" && (
                          <img src={signupForm.profileImgPreview} alt="img" />
                        )}
                      {signupForm.profileImg &&
                        signupForm.profileImg !== "" &&
                        !signupForm.profileImgPreview && (
                          <img
                            src={`${
                              process.env.REACT_APP_STATIC_S3_URL +
                              signupForm.profileImg
                            }`}
                            alt="img"
                          />
                        )}
                      {!signupForm.profileImg &&
                        !signupForm.profileImgPreview && (
                          <img src="/static/img/icon-profiles.png" alt="img" />
                        )}
                      <input
                        type="file"
                        name="profileImg"
                        accept=".bmp,.jpg,.png,.jpeg"
                        onChange={(e) => fileChangedHandler(e, "profileImg")}
                        style={{ display: "none" }}
                      />
                    </label>
                    <br />
                    <br />
                    <div className="text-danger">
                      {ShowFormErrors(errors, signupErrors, "profileImg")}
                    </div>
                  </div>
                  <div className="userid">
                    <p>Clinic Code: {signupForm.clinicNumberName}</p>

                    <button
                      type="button"
                      className="nbtn"
                      onClick={(e) => setIsChangePassword(true)}
                    >
                      <span>Change Password</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <Dialog
            className="form_designpage"
            onClose={(e) => setIsChangePassword(false)}
            title=""
            canOutsideClickClose={false}
            isOpen={isChangePassword}
          >
            <ChangePassword
              history={history}
              setIsChangePassword={setIsChangePassword}
            />
          </Dialog>
          <div
            className="spinner"
            style={{ display: spinner ? "block" : "none" }}
          >
            <img src="/static/img/spinner.gif" alt="loader" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(ProfilePage);
