import React from 'react'

function Popup (props) {
    
    // console.log("pppppppppp",props.availabilityList)
    console.log("pppppppppp",props.selectedDoc.item.fullName)


        return (
            <>
      <div className="pop1">
      <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                              Provider Information
                            </h4>
                          </div>
      <div className="modal-body py-0">
        <table className="table">
          <thead></thead>
          <tbody>
            <tr>
              <td className="tr">Full Name</td>

              <td className="tr">{props.selectedDoc.item.fullName}</td>
            </tr>
            <tr>
              <td className="tr">Credentials</td>

              <td className="tr">{props.selectedDoc.item.education}</td>
            </tr>
            <tr>
              <td className="tr">License Number</td> 

              <td className="tr">{props.selectedDoc.item.licenseNumber}</td>
            </tr>
            {/* <tr>
              <td >Charge Clients For 30 Minute Sessions</td>

              <td>${props.selectedDoc.item.price_for_30_min}</td>
            </tr>
            <tr>
              <td>charge Clients For 60 Minute Sessions</td>

              <td>${props.selectedDoc.item.price_for_60_min}</td>
            </tr> */}
            <tr className="trr">
              <td>Specialization</td>
              <td>{props.selectedDoc.
    item.specialization.map(function(ttttt, index) {
      return <span key={`demo_snap_${index}`}>{ (index ? ', ' : '') + ttttt.specializationName }</span>;
    })
  }</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


            </>
        )

}

export default Popup