import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { withLocalize, Translate } from "react-localize-redux";
import SideMenu from "../DashboardPage/SideMenu";

import {
  getUserProfileAction,
  providerList,
  specializationList,
  getPTAvailabilityList,
  requestProviderAppointment,
} from "../../Actions/User.action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toastr from "../../../_Common/toaster";
import MetaTags from "react-meta-tags";
import BreadcrumbsComp from "../../../_Common/Breadcrumbs";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { FormError, ShowFormErrors } from "../../../_Common/formError";
import "react-datepicker/dist/react-datepicker.css";

function RequestPop(props) {
 
  const [spinner, setSpinner] = useState(false);
  const [signupErrors, setSignupErrors] = useState({});
  const [data,setData]=useState({});
  const { register, handleSubmit, setValue, errors, setError, reset } =
  useForm();
  const [signupForm, setSignupForm] = useState({});


  console.log("props.selectedDoc email",props.selectedDoc)
  useEffect(()=>{
    let temp={};
    temp.clientName=props.profileData.fullName;
    temp.clientEmail  =props.profileData.email;
    temp.clientPhoneNo =props.profileData.phone;


    temp.providerName=props.selectedDoc.item.fullName;
    temp.education=props.selectedDoc.item.education;
    temp.providerEmail =props.selectedDoc.item.email;

    temp.licenseNumber=props.selectedDoc.item.licenseNumber;
    temp.price_for_30_min=props.selectedDoc.item.price_for_30_min;
    temp.price_for_60_min=props.selectedDoc.item.price_for_60_min;
    temp.phone=props.selectedDoc.item.phone;
    temp.providerId=props.selectedDoc.item._id;

    console.log(props.selectedDoc.item.specialization)
    let specilizationArray=[];
    props.selectedDoc.item.specialization.map(function(listItem,key){
      specilizationArray.push(listItem.specializationName);
    });
    temp.specializationName=specilizationArray;
    setData(temp)
  },[])
  
  const onFormSubmit = async () => {
    

    console.log("ppppppppppppttt", data);
    let res = {};
    let formErrors = {};
    res = await requestProviderAppointment(data);

    console.log("pppppppppppp", data);
    if (res.err && res.err.length > 0) {
      formErrors = FormError(res.err);
    }
    setSignupErrors(formErrors);
    if (res.success) {
      setSpinner(false);
      toastr.show({
        message:
          "Appointment Request has been successfully send",
        intent: "success",
      });
      document.getElementById("resetForm").reset();
      setSignupForm("");
    } else if (res.success === false) {
      setSpinner(false);
      toastr.show({ message: res.msg, intent: "danger" });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)} id="resetForm">
        <div className="pop1">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Client Name
            </h4>
          </div>
          <div className="modal-body py-0">
            <table className="table">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tr">Client Name</td>
                  <td className="tr" innerRef={register({
                      required: 'Required'
                    })}
                         name="clientName" >{props.profileData.fullName}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Provider Information
            </h4>
          </div>
          <div className="modal-body py-0">
            <table className="table">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tr">Provider Name</td>

                  <td className="tr"   
                         name="providerName">{props.selectedDoc.item.fullName}</td>
                </tr>
                <tr>
                  <td className="tr">Credentials</td>

                  <td className="tr"    name="education">{props.selectedDoc.item.education}</td>
                </tr>
                <tr>
                  <td className="tr">License Number</td>

                  <td className="tr"   name="licenseNumber">{props.selectedDoc.item.licenseNumber}</td>
                </tr>
                <tr>
                  <td>Charge Clients For 30 Minute Sessions</td>

                  <td    name="price_for_30_min">${props.selectedDoc.item.price_for_30_min}</td>
                </tr>
                <tr>
                  <td>Charge Clients For 60 Minute Sessions</td>

                  <td    name="price_for_60_min">${props.selectedDoc.item.price_for_60_min}</td>
                </tr>
                <tr>
                  <td>Contact Number</td>

                  <td    name="phone">{props.selectedDoc.item.phone.replace(
                              /(\d{3})(\d{3})(\d{4})/,
                              "$1-$2-$3"
                            )}</td>
                </tr>
                <tr className="trr">
                  <td>Specialization</td>
                  <td  
                   
                  name="specializationName">
                    {props.selectedDoc.item.specialization?.map(function (
                      ttttt,
                      index
                    ) {
                      return (
                        <span key={`demo_snap_${index}`}>
                          {(index ? ", " : "") + ttttt.specializationName}
                        </span>
                      );
                    })}
                  </td>
                </tr>
               
              </tbody>
             
            </table>
            <div className="mahii">
                  <input
                    type="submit"
                    className="btn btn-primary "
                    value="Submit"
                  />
                </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default RequestPop;
