import React,{useState,useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import {getFeedbackDetailAction} from '../../Actions/User.action';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';

const FeedbackDetailPage = ({history}) => {
  var feedbackDetail = '';
  if(history && history.location && history.location.state && history.location.state.id!==''){
    feedbackDetail = history.location.state.id;    
  }else{
    history.push('/');
  }

  /*const [feedbackDetail,setFeedbackDetail] = useState({});
    const getFeedbackDetailFunction = async()=>{
    if(history && history.location && history.location.state && history.location.state.id!==''){
      let feedbackData = await getFeedbackDetailAction({_id:history.location.state.id});    
      setFeedbackDetail(feedbackData.data);
    }else{
      history.push('/');
    }
  }
*/
  useEffect(()=>{
    document.documentElement.scrollTop = 0;
    //getFeedbackDetailFunction();
  },[]);

  return (
    <div>
      <MetaTags>
        <title>Feedback Detail</title>
        <meta name="description" content="To access e-INSCRIPTION’s services through a website or mobile app, you need an account on our platform. To create an account you have to provide accurate info" />
        <meta name="keywords" content="LIVE online video streaming providers Indore, online video streaming providers, Online teaching in Indore, Online Training in Indore, Virtual classes in Indore " />
      </MetaTags>

      <section className="feedbackDetail pad-reggis inner_pagewrap about_page">
        <BreadcrumbsComp bcChanges={[{path: '/feedback-detail', breadcrumb: 'Feedback Detail'}]} />
        
        <div className="container">
          <div className="row white_box">
            <div className="col-lg-12 col-sm-12">
              <div className="section_title page_ttl text-center">
                <h3>Feedback Detail</h3>
                <div className="decor2"></div>
              </div>
            </div>
            <div className="col-sm-12 text-left text_content">
              <div className="about_con_wrap">
                <h4>
                  {feedbackDetail.name && feedbackDetail.name.length > 0 ?feedbackDetail.name:'-'}
                </h4><br/>
                <p>
                  {/*feedbackDetail.description && feedbackDetail.description.length > 0 ?feedbackDetail.description.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' '):'-'*/}
                  {feedbackDetail.description?feedbackDetail.description.replace(/<(?:|\/div|\/p)>/g, "\n").replace(/&nbsp;|<.*?>/g, ""):'-'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default withRouter(FeedbackDetailPage);
