import React from 'react';
import SplitFieldsForm from './SplitFieldsForm';
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';

const PageWithStripeElements = ({history}) => {  
  var stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

  {(localStorage.getItem("userType")!=='PATIENT') &&
      history.push('/');
  }

  var bookingArr = '';
  {history.location.state === undefined ? 
      history.push('/provider-list')
    : 
      bookingArr =  history.location.state.bookingArr;
  }

  return (
    <section className="pad-reggis checkout_page">
      <div className="container">
        <Elements stripe={stripePromise}>
          <SplitFieldsForm history={history} bookingArr={bookingArr} />
        </Elements>
      </div>
    </section>
  );
}
export default PageWithStripeElements;